import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import SubmitButton from "../../Components/submitButton";
import { usePagination } from "../../Utils/appConstants";
import { useNavigate } from "react-router-dom";
import { addQuestionConfig, bindSubjectList, deleteQuestionConfig, getCountryList, getCourseTypelist, getExamSetTypeId, getGeneralExamList, getPatternList, getQuestionConfigList } from "../../Redux/Actions/AdminAction";
import PaginationTable from "../../Components/paginationTable"
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import { containsNumber, containsNumber1to100, length_One, length_Three, validateNameWithoutSpace } from "../../Utils/validators";
import { validateFullNameErrMsg } from "../../Utils/errorMsg";
import { GradeList } from "../../Utils/dataConstant";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import { studentLevelList } from "../../Utils/dataConstant";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
export default function UpdateQuestionConfig() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState<any>([]);
  const [isEditMode, setisEditMode] = useState<any>(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [toggle, setToggle] = useState<any>(false);
  const [examtype, setExamType] = useState<any>("");
  const [examtypeList, setExamtypeList] = useState<any>([]);
  const [resetExamTypeList, setResetExamTypeCourseList] = useState(false);
  const [examPattern, setExamPattern] = useState<any>("");
  const [examPatternList, setExamPatternList] = useState([]);
  const [resetExamPatternList, setResetExamPatternList] = useState(false);
  const [studentLevel, setstudentLevel] = useState<any>("");
  const [resetStudentLevelList, setResetStudentLevelList] = useState(false);
  const [question, setQuestion] = useState<any>();
  const [formateTotal, setFormateTotal] = useState<any>("");
  const [priorityTotal, setPriorityTotal] = useState<any>("");
  const [typeTotal, setTypeTotal] = useState<any>("");

  const initialVal: {
    level: any;
    percentage: any;
  }[] = [
      {
        level: 4,
        percentage: "",
      },
      {
        level: 1,
        percentage: "",
      },
      {
        level: 2,
        percentage: "",
      },
      {
        level: 3,
        percentage: "",
      },
    ];
  const [questionFormat, setQuestionFormat] = useState(initialVal);

  const initialQuesType: {
    level: any;
    percentage: any;
  }[] = [
      {
        level: 1,
        percentage: "",
      },
      {
        level: 2,
        percentage: "",
      },
      {
        level: 3,
        percentage: "",
      },
      {
        level: 4,
        percentage: "",
      },
      {
        level: 5,
        percentage: "",
      },
      {
        level: 6,
        percentage: "",
      },
    ];
  const [questionType, setQuestionType] = useState(initialQuesType);
  const initialQuesPriority: {
    level: any;
    percentage: any;
  }[] = [
      {
        level: 1,
        percentage: "",
      },
      {
        level: 2,
        percentage: "",
      },
      {
        level: 3,
        percentage: "",
      },
    ];
  const [questionPriority, setQuestionPriority] = useState(initialQuesPriority);
  useEffect(() => {

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );

    getList()
  }, []);

  const getList = () => {
    dispatch(
      getQuestionConfigList(
        {},
        (res: any) => {
          setList(res?.data);
        },
        () => { }
      )
    );

  }
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value?._id);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value?._id);
    setResetParentCourse(false);
    setresetExam(true);
    setResetSubject(true);
    setResetGradeList(true);
    setResetExamTypeCourseList(true);
    setResetExamPatternList(true);
    setResetStudentLevelList(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value?._id);
    setResetGradeList(false);
    setResetSubject(true);
    setResetExamTypeCourseList(true);
    setResetExamPatternList(true);
    setResetStudentLevelList(true);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    setResetSubject(true);
    setResetExamTypeCourseList(true);
    setResetExamPatternList(true);
    setResetStudentLevelList(true);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    setResetExamTypeCourseList(true);
    setResetExamPatternList(true);
    setResetStudentLevelList(true);
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamtypeList(res);
        },
        () => { }
      )
    );
  };
  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExamType(value._id);
    setResetExamPatternList(true);
    setResetStudentLevelList(true);
    dispatch(
      getPatternList(
        {},
        (res: any) => {
          setExamPatternList(res);
        },
        () => { }
      )
    );
  };
  const onChangeExamPattern = (value: { _id: any; name?: string }) => {
    setExamPattern(value._id);
    setResetExamPatternList(false);
    setResetStudentLevelList(true);
  };
  const onChangeLevel = (value: { _id: any; name?: string }) => {
    setstudentLevel(value?._id);
    setResetStudentLevelList(false);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const onChangeQueFormat = (val: any, index: number) => {
    if (!containsNumber1to100(val) && val !== "") {
      dispatch(
        showErrorSnackbar("Only numbers are allowed in between 1 to 100")
      );
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      const newArrr = [...questionFormat];
      newArrr[index]["percentage"] = val;
      let totalQuestionFormate = 0;
      for (let x of newArrr) {
        if (isNaN(x.percentage)) {
          totalQuestionFormate += 0;
        } else {
          totalQuestionFormate += parseInt(x.percentage);
        }

      }
      setQuestionFormat(newArrr);
      setFormateTotal(totalQuestionFormate);
    }
  };
  const onChangeQueType = (val: any, index: number) => {
    if (!containsNumber1to100(val) && val !== "") {
      dispatch(
        showErrorSnackbar("Only numbers are allowed in between 1 to 100")
      );
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      const newArrr = [...questionType];
      newArrr[index]["percentage"] = val;
      let totalQuestionType = 0;
      for (let x of newArrr) {
        if (isNaN(x.percentage)) {
          totalQuestionType += 0;
        } else {
          totalQuestionType += parseInt(x.percentage);
        }

      }
      setQuestionType(newArrr);
      setTypeTotal(totalQuestionType);
    }
  };
  const onChangeQuePriority = (val: any, index: number) => {
    if (!containsNumber1to100(val) && val !== "") {
      dispatch(
        showErrorSnackbar("Only numbers are allowed in between 1 to 100")
      );
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      const newArrr = [...questionPriority];
      newArrr[index]["percentage"] = val;
      let totalQuestionPriority = 0;
      for (let x of newArrr) {
        if (isNaN(x.percentage)) {
          totalQuestionPriority += 0;
        } else {
          totalQuestionPriority += parseInt(x.percentage);
        }
      }
      setQuestionPriority(newArrr);
      setPriorityTotal(totalQuestionPriority);
    }
  };
  const onChangeQuestions = (val: any) => {
    if (!containsNumber(val) && val !== "") {
      dispatch(showErrorSnackbar("Only numbers are allowed"));
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      setQuestion(val);
    }
  };

  const validatequestionFormat = () => {
    let isQuestionFormatArr: boolean = false;
    questionFormat?.map((item: any) => {
      if (item?.level === "" || item?.percentage === "") {
        isQuestionFormatArr = true;
      }
    });
    return isQuestionFormatArr;
  };

  const validatequestionType = () => {
    let isQuestionTypeArr: boolean = false;
    questionType?.map((item: any) => {
      if (item?.level === "" || item?.percentage === "") {
        isQuestionTypeArr = true;
      }
    });
    return isQuestionTypeArr;
  };

  const validatequestionPriority = () => {
    let isQuestionPriorityArr: boolean = false;
    questionPriority?.map((item: any) => {
      if (item?.level === "" || item?.percentage === "") {
        isQuestionPriorityArr = true;
      }
    });
    return isQuestionPriorityArr;
  };

  const validations = () => {
    return (
      country === "" ||
      course === "" ||
      subject === "" ||
      toggle === false ||
      studentLevel === "" ||
      examtype === "" ||
      examPattern === "" ||
      question === "" ||
      validatequestionFormat() ||
      validatequestionPriority() ||
      validatequestionType()
    );
  };
  const handleEdit = (value: any) => {
    setisEditMode(true);
    setCountry(value?.country)
    setCourse(value?.coursetype);
    setGrade(value?.grades);
    setExam(value?.examtype)
    setSubject(value?.subject)
    setExamType(value?.examSetType)
    setExamPattern(value?.examPattern)
    setstudentLevel(value?.studentLeve)
    setToggle(value?.isActive)
    setQuestionFormat(value?.questionFormat)
    setQuestionType(value?.QuestionType)
    setQuestionPriority(value?.priority)
    setQuestion(value?.totalQuestions)


  };

  const handleUpdate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      coursetypeId: course,
      examtypeId: exam,
      gradeId: grade,
      subjectId: subject,
      examSetTypeId: examtype,
      totalQuestions: question,
      examPatternId: examPattern,
      isActive: toggle,
      questionFormat: questionFormat,
      QuestionType: questionType,
      priority: questionPriority,
      studentLevelId: studentLevel,
    };
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    dispatch(
      addQuestionConfig(
        Postdata,
        (data: any) => {
          setResetParentCourse(true);
          setresetExam(true);
          setResetSubject(true);
          setResetGradeList(true);
          setResetCountryList(true);
          setResetExamTypeCourseList(true);
          setResetExamPatternList(true);
          setResetStudentLevelList(true);
          setQuestionFormat(initialVal);
          setQuestionType(initialQuesType);
          setQuestionPriority(initialQuesPriority);
          setToggle(false);
          setQuestion("");
        },
        () => { }
      )
    );

  }


  const renderListMode = () => {
    return (
      <>
        <div className="p-2 table-responsive">
          <PaginationTable
            tableData={list ? list : []}
            pagename={"QuestionConfig"}
            // rowsPerPage={rowsPerPage}
            // page={page}
            tableHead={["S.N",
              "Course",
              "Exam",
              "Exam Set",
              "Subject",
              "Pattern",
              "Active",
              "Actions",]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
          />
        </div>
      </>
    );
  };

  const renderData = () => {
    return (
      <>
        <div className="row d-flex px-2 mx-0 mt-4 ">
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Country"} />
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                reset={resetCountryList}
                defaultValue={countryList.length > 0 ? country?._id : ""}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Course Type"} />
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                reset={resetParentCourse}
                defaultValue={courseList?.length > 0 ? course?._id : ""}
              />
            </div>
          </div>
          {course?.type === 2 ? (
            <div className="col-sm-3">
              <div className="d-flex mb-2 align-items-center">
                <LabelField lableName={"Student Grade"} />
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  options={GradeList}
                  reset={resetGradeList}
                  defaultValue={GradeList?.length > 0 ? grade?._id : ""}
                />
              </div>
            </div>
          ) : null}
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="col-sm-3">
              <div className="d-flex mb-2 align-items-center">
                <LabelField lableName={"Exam Name "} />
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  reset={resetExam}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  defaultValue={examList?.length > 0 ? exam?._id : ""}
                />
              </div>
            </div>
          )}
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Subject Name"} />
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                reset={resetSubject}
                defaultValue={subjectList?.length > 0 ? subject?._id : ""}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Exam Type"} />
              <CustomDropdown
                lableName="Select Exam Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExamType(value)
                }
                value={examtype}
                options={examtypeList}
                reset={resetExamTypeList}
                defaultValue={examtypeList?.length > 0 ? examtype?._id : ""}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Exam Pattern"} />
              <CustomDropdown
                lableName="Select Exam Pattern"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExamPattern(value)
                }
                value={examPattern}
                options={examPatternList}
                reset={resetExamPatternList}
                defaultValue={examPatternList?.length > 0 ? examPattern?._id : ""}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Student Level"} />
              <CustomDropdown
                lableName="Select Student Level"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeLevel(value)
                }
                value={studentLevel}
                options={studentLevelList}
                reset={resetStudentLevelList}
                defaultValue={studentLevelList?.length > 0 ? studentLevel?._id : ""}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Active"} />
              <div className="ms-2 mt-3">
                <ToggleSwitchbtn
                  getToggleval={(value: any) => {
                    changeToggle(value);
                  }}
                  isActive={toggle}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderQuestionFormate = () => {
    return (
      <div className="row m-3 border border-dark py-3 text-start">
        <div className="col-md-12">
          <h4 className="mb-3">{"Question Format"}</h4>
          <div className="d-flex justify-content-between mb-3">
            {questionFormat?.map((item: any, index: any) => {
              return (
                <div className="col-md-3 mb-2 align-items-center">
                  <div className="ps-2 mb-2">
                    <LabelField
                      lableName={
                        item?.levelId === 1
                          ? "High"
                          : item?.levelId === 2
                            ? "Medium"
                            : item?.levelId === 3
                              ? "Low"
                              : item?.levelId === 4
                                ? "Complex"
                                : ""
                      }
                    />
                  </div>
                  <InputField
                    placeholder="Enter Question Percentage"
                    value={item.percentage}
                    onChangeInput={(value: any) =>
                      onChangeQueFormat(value, index)
                    }
                    isRequired={true}
                  />
                </div>
              );
            })}
          </div>
          {formateTotal === 100 ? (
            ""
          ) : (
            <p className="text-danger">
              * Total Percentage Of Question Format will not be Greater or Less
              than 100
            </p>
          )}
        </div>
      </div>
    );
  };
  const renderQuestiontype = () => {
    return (
      <>
        <div className="row m-3 border border-dark py-3 text-start">
          <h4 className="mb-3">{"Question Type"}</h4>
          <div className="d-flex justify-content-between mb-3">
            {questionType?.map((item: any, index: any) => {
              return (
                <div className="mb-2 align-items-center">
                  <div className="ps-2 mb-2">
                    <LabelField
                      lableName={
                        item?.levelId === 1
                          ? "MCQ"
                          : item?.levelId === 2
                            ? "Fixed"
                            : item?.levelId === 3
                              ? "MSQ"
                              : item?.levelId === 4
                                ? "DISCRIPTIVE"
                                : item?.levelId === 5
                                  ? "MATCH THE PAIR"
                                  : item?.levelId === 6
                                    ? "COADING"
                                    : ""
                      }
                    />
                  </div>
                  <InputField
                    placeholder="Enter Percentage"
                    value={item.percentage}
                    onChangeInput={(value: any) =>
                      onChangeQueType(value, index)
                    }
                    isRequired={true}
                  />
                </div>
              );
            })}
          </div>
          {typeTotal === 100 ? (
            ""
          ) : (
            <p className="text-danger">
              * Total Percentage Of Question Type will not be Greater or Less
              than 100
            </p>
          )}
        </div>
      </>
    );
  };
  const renderQuestionPriority = () => {
    return (
      <>
        <div className="row m-3 border border-dark py-3 text-start">
          <h4 className="mb-3">{"Question Priority"}</h4>
          <div className="d-flex justify-content-between mb-3">
            {questionPriority?.map((item: any, index: any) => {
              return (
                <div className="col-md-3 mb-2 align-items-center">
                  <div className="ps-2 mb-2">
                    <LabelField
                      lableName={
                        item?.levelId === 1
                          ? "High"
                          : item?.levelId === 2
                            ? "Medium"
                            : item?.levelId === 3
                              ? "Low"
                              : ""
                      }
                    />
                  </div>
                  <InputField
                    placeholder="Enter Percentage"
                    value={item.percentage}
                    onChangeInput={(value: any) =>
                      onChangeQuePriority(value, index)
                    }
                    isRequired={true}
                  />
                </div>
              );
            })}
          </div>
          {priorityTotal === 100 ? (
            ""
          ) : (
            <p className="text-danger">
              * Total Percentage Of Priority Question will not be Greater or
              Less than 100
            </p>
          )}
        </div>
      </>
    );
  };

  const renderEditMode = () => {
    return (<>

      <div className="formDiv">
        <form className="text-center" onSubmit={handleUpdate}>
          <div className="col-auto">{renderData()}</div>
          <div className="col-auto">{renderQuestionFormate()}</div>
          <div className="col-auto">{renderQuestiontype()}</div>
          <div className="col-auto">{renderQuestionPriority()}</div>
          <div className="col-md-4">
            <div className="d-flex  mb-2 align-items-center">
              <div className="col-md-4 ps-4 mb-2">
                <LabelField lableName={"No of Questions"} />
              </div>
              <InputField
                placeholder="Enter Percentage"
                value={question}
                onChangeInput={(value: any) => onChangeQuestions(value)}
                isRequired={true}
                maxlength={length_Three - 1}
                minlength={length_One}
              />
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center mt-4">
            <SubmitButton name={"Submit"} validation={validations()} />
          </div>
        </form>
      </div>


    </>)
  }
  return (
    <div className="contentBox">
      <PageTitle name={"EDIT QUESTION CONFIGURATION"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        {isEditMode ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
