export default {
  CouncellorSidebarData: [
    {
      title: "Reference Leads",
      subNav: [
        {
          title: "CREATE",
          path: "referenceUser",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "referenceUserList",
          cName: "sub-nav",
        },
      ],
    },
    // {
    //   title: "COUNSELLOR PAYMENTS",
    //   path: "counsellorPayments",
    // },
    {
      title: "STUDENT PAYMENT LINK",
      subNav: [
        {
          title: "CREATE PAYMENT LINK",
          path: "studentPaymentLink",
          cName: "sub-nav",
        },
        {
          title: "PAYMENT LINKS STATUS",
          path: "counsellorStudentPayLinks",
          cName: "sub-nav",
        },
      ],
    },
  ],
};
