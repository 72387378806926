import React, { useState } from "react";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Atoms/submitbtn";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { superAdminLogin } from "../../Redux/Actions/AuthAction";
import jwt_decode from "jwt-decode";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";

export default function Login() {
  const navigate = useNavigate();
  const dispatch: Function = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const roleRoutes: Record<number, string> = {
    1: "../SuperAdminPanel",
    2: "../AdminPanel",
    3: "../CouncellorPanel",
    4: "../TeachersPanel",
    5: "../DataEntrypanel",
    6: "../SalesPanel",
    8: "../InfraPanel",
    9: "../Finance",
    10: "../CXO",
    11: "../Technical",
    12: "../CategoryPanel",
    13: "../HrOperationPanel",
    14: "../OperationPanel",
    15: "../Configuration",
    16: "../DsaManager",
    17: "../DsaSales",
    18: "../Academics",
    20: "../Affiliate",
    21: "../DsaUser",
    22: "../SocialMediaPanel",
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      username: userName,
      password: password,
    };
    dispatch(
      superAdminLogin(
        postData,
        (res: any) => {
          if (res.token) {
            var decoded: any = jwt_decode(res.token.access.token);
            if (decoded) {
              dispatch(updateCommonValues("userName", decoded?.name));
              dispatch(updateCommonValues("userId", decoded?._id));
              dispatch(updateCommonValues("countryId", decoded?.countryId));
              dispatch(updateCommonValues("userEmail", decoded?.email));
              const panelRoute = roleRoutes[decoded.role];
              if (panelRoute) {
                navigate(panelRoute);
              }
            }
          }
        },
        () => {}
      )
    );
  };

  return (
    <div className="loginContainer">
      <form className="text-center" onSubmit={handleSubmit}>
        <h2>Lurnigo Admin</h2>
        <div>
          <InputField
            placeholder="Enter Your Name"
            value={userName}
            onChangeInput={(value: string) => setUserName(value)}
          />
        </div>
        <div>
          <InputField
            placeholder="Enter Password"
            value={password}
            onChangeInput={(value: string) => setPassword(value)}
          />
        </div>
        <div className="col-auto d-flex justify-content-center mt-4">
          <Submitbtn name="Submit" />
        </div>
      </form>
    </div>
  );
}
