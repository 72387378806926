import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import {
  getCountryList,
  getCourseTypelist,
  getRegistrationDetails,
  register,
} from "../../Redux/Actions/AdminAction";
import {
  length_Eight,
  length_Ten,
  validateFullName,
  validateIsNumberOnly,
} from "../../Utils/validators";
import {
  validateEmailAddressErrMsg,
  validateFullNameErrMsg,
  validateLastNameErrMsg,
} from "../../Utils/errorMsg";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import Dropdown from "../../Components/dropdown";
export default function ReferenceUser() {
  const dispatch: Function = useDispatch();
  const [mobileNo, setMobileNo] = useState<string>("");
  const [country, setcountryCode] = useState<any>("");
  const [userManualEntry, setUserManualEntry] = useState(false);
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [course, setCourse] = useState<any>("");
  const [pname, setpname] = useState("");
  const [pmobno, setpmobno] = useState("");
  const [pemail, setpemail] = useState<string>("");

  const [countryList, setCountryList] = useState([]);
  const [courseList, setCourseList] = useState([]);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (val: any) => {
    setcountryCode(val);
    setMobileNo("");
    setfname("");
    setlname("");
    setemail("");
    setCourse("");
    setpname("");
    setpmobno("");
    setpemail("");
    if (val) {
      const postData = {
        countryId: val?._id,
      };
      dispatch(
        getCourseTypelist(
          postData,
          (res: any) => {
            setCourseList(res);
          },
          () => { }
        )
      );
    }
  };

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const phoneNumberLengthValidation = (number: string) => {
    if (country?._id === 13) {
      return number?.length !== length_Eight;
    } else if (country?._id === 13) {
      return number?.length !== length_Ten;
    }
  };

  const phoneValidations = () => {
    return (
      mobileNo === "" || phoneNumberLengthValidation(mobileNo) || country === ""
    );
  };

  const onSearchMobile = () => {
    const postData = {
      mobile: mobileNo,
      countryCode: country?.phonecode?.toString(),
    };
    if (
      postData["countryCode"] === null ||
      postData["countryCode"] === undefined ||
      postData["countryCode"] === ""
    ) {
      delete postData["countryCode"];
    }
    dispatch(
      getRegistrationDetails(
        postData,
        (res: any) => {
          if (res !== null) {
            setUserManualEntry(true);
            dispatch(showSuccessSnackbar("Record already exists."));
          }
        },
        () => {
          setUserManualEntry(false);
          setfname("");
          setlname("");
          setemail("");
        }
      )
    );
  };

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };

  const handleLastInputLnameText = (e: any) => {
    if (validateFullName(e)) setlname(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
  };

  const handleParentNameInputLnameText = (e: any) => {
    if (validateFullName(e)) setpname(e);
    else {
      dispatch(showErrorSnackbar("Please enter valide Parent Name"));
    }
  };

  const onChangeParentMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setpmobno(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      const payload: any = {
        firstName: fname,
        lastName: lname,
        mobile: mobileNo,
        email: email,
        parentEmail: pemail,
        parentName: pname,
        parentNumber: pmobno,
        country: "+" + country?.phonecode,
        coursetypeId: course?._id,
        refId: userId,
        sourceId: 7,
      };
      if (pemail === "") {
        delete payload.parentEmail;
      }
      if (pname === "") {
        delete payload.parentName;
      }
      if (pmobno === "") {
        delete payload.parentNumber;
      }
      dispatch(
        register(
          payload,
          () => {
            setcountryCode("");
            setMobileNo("");
            setfname("");
            setlname("");
            setemail("");
            setpname("");
            setpemail("");
            setpmobno("");
            setCourse("");
          },
          () => { }
        )
      );
    } else {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg));
      return false;
    }
  };

  function validation() {
    return (
      fname === "" ||
      lname === "" ||
      country === "" ||
      mobileNo === "" ||
      mobileNo?.length < 9 ||
      email === ""
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE REFERENCE USER"} />
      <div className="px-4 mx-0 mt-2 formDiv ">
        <form className="col-6 pt-2" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Country"} isRequired={true} />
            </div>
            <div className="col-8">
              <Dropdown
                labelName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Mobile Number"} />
            </div>
            <div className="col-6 me-2">
              <InputField
                placeholder="Enter Mobile Number"
                value={mobileNo}
                onChangeInput={(value: any) => onChangeMobileNO(value)}
                maxlength={length_Ten}
                isRequired={true}
              />
            </div>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-dark rounded-pill px-3 "
                onClick={() => onSearchMobile()}
                disabled={phoneValidations()}
              >
                Search
              </button>
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"First Name"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter First Name"
                value={fname}
                onChangeInput={(value: any) => handleFirstInputText(value)}
                isRequired={true}
                minlength={2}
                maxlength={150}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Last Name"} />
            </div>
            <div className="col-8">
              <InputField
                placeholder="Enter Last Name"
                value={lname}
                onChangeInput={(value: any) => handleLastInputLnameText(value)}
                isRequired={true}
                minlength={2}
                maxlength={150}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Email Address"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => setemail(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Course type"} isRequired={true} />
            </div>
            <div className="col-8 ps-2">
              <Dropdown
                labelName="Select Course"
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Parent Name"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter Parent Name"
                value={pname}
                onChangeInput={(value: any) =>
                  handleParentNameInputLnameText(value)
                }
                maxlength={100}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Parent Mobile Number"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter Parent Mobile Number"
                value={pmobno}
                onChangeInput={(value: any) => onChangeParentMobileNO(value)}
                maxlength={length_Ten}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Parent Email Address"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter Parent Email Address"
                value={pemail}
                onChangeInput={(value: any) => setpemail(value)}
              />
            </div>
          </div>

          {!userManualEntry ? (
            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Create User"} validation={validation()} />
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}
