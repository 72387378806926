import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import { useDispatch } from "react-redux";
import {
  length_Two,
  length_TwoHundredFifty,
  validateSubject,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsAlphabetErrMsg } from "../../Utils/errorMsg";
import {
  deleteSubject,
  getNewSubjectList,
  updateSubject,
} from "../../Redux/Actions/AdminAction";
import SubmitButton from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import AdminTable from "../../Components/adminTable";

export default function UpdateSubject() {
  const dispatch: Function = useDispatch();
  const [subjectList, setSubjectList] = useState([]);
  const [subjectname, setSubjectname] = useState<string>("");
  const [SubjectID, setSubjectID] = useState<string>("");
  const [isEditMode, setisEditMode] = useState(false);
  const [seoSubjectName, setSeoSubjectName] = useState("");

  useEffect(() => {
    updatedata();
  }, []);

  const updatedata = () => {
    dispatch(
      getNewSubjectList(
        "",
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setSubjectname(value?.name);
    setSubjectID(value?._id);
    setSeoSubjectName(value?.seoSubjectName);
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteSubject(
        value._id,
        () => {
          updatedata();
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      name: subjectname,
      seoSubjectName: seoSubjectName,
    };
    dispatch(
      updateSubject(
        Postdata,
        SubjectID,
        (res: any) => {
          setisEditMode(false);
          setSubjectname("");
          setSubjectID("");
          updatedata();
        },
        () => {}
      )
    );
  };

  function validation() {
    return subjectname === "" || seoSubjectName === "";
  }

  function onchangeSubject(val: any) {
    if (validateSubject(val)) {
      setSubjectname(val);
    } else {
      dispatch(showErrorSnackbar(validateIsAlphabetErrMsg));
      return;
    }
  }

  const onChangeSeoSubjectname = (value: any) => {
    setSeoSubjectName(value);
  };

  const renderListMode = () => {
    return (
      <AdminTable
        tableData={subjectList ? subjectList : []}
        pagename={"subject"}
        tableHead={["S.N", "Subject Name", "ACTION"]}
        edit={true}
        handleEdit={(value: any) => handleEdit(value)}
        handleDelete={(value: any) => handleDelete(value)}
      />
    );
  };

  const renderEditMode = () => {
    return (
      <div className="mx-0 mt-1  ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7">
            <div className="col-11 d-flex Justify-content-between mt-3">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter Subject Name"
                  value={subjectname}
                  onChangeInput={(value: any) => onchangeSubject(value)}
                  maxlength={150}
                  minlength={3}
                />
              </div>
            </div>
            <div className="col-11 d-flex Justify-content-between mt-3">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"SEO Subject Name"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter SEO Subject Name"
                  value={seoSubjectName}
                  onChangeInput={(value: any) => onChangeSeoSubjectname(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={length_Two}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <SubmitButton name={"Save & Update"} validation={validation()} />
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT SUBJECT"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="px-4 mx-0 pt-4 formDiv">
        <div className="col-7"></div>
        {isEditMode ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
