import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Screens/Auth/login";
import AdminPanelDashboard from "./Screens/Admin/adminPanelDashboard";
import SuperAdminPanelDashboard from "./Screens/SuperAdmin/superAdminPanelDashboard";
import SalesPanelDashboard from "./Screens/SalesPanel/salesPanelDashboard";
import DataEntryPanelDashboard from "./Screens/DataEntry/dataEntryPanelDashboard";
import InfraPanelDashboard from "./Screens/InfraPanel/infraPanelDashboard";
import CouncellorPanelDashboard from "./Screens/CouncellorPanel/councellorPanelDashboard";
import TeachersPanelDashboard from "./Screens/TeachersPanel/teachersPanelDashboard";
import FinanceDashboard from "./Screens/Finance/financeDashboard";
import TechnicalDashboard from "./Screens/TechnicalPanel/technicalDashboard";
import CXODashboard from "./Screens/CXO/CXODashboard";
import CategoryPanelDashboard from "./Screens/CategoryPanel/categoryPanelDashboard";
import SuccessSnackbar from "./Components/successSnackbar";
import ErrorSnackbar from "./Components/errorSnackbar";
import HrOperationPanelDashboard from "./Screens/HrOperation/hrOperationPanelDashboard";
import OperationDashboard from "./Screens/Operations/OperationDashboard";
import ConfigurationPanelDashboard from "./Screens/Configuration/configurationPanelDashboard";
import DsaManagerPanelDashboard from "./Screens/DSAManager/dsaManagerPanelDashboard";
import DsaSalesPanelDashboard from "./Screens/DSA Sales/dsaSalesPanelDashboard";
import AcademicsPanelDashboard from "./Screens/Academics/academicsPanelDashboard";
import AffiliatePanelDashboard from "./Screens/Affiliate/affiliatePanelDashboard";
import DsaUserPanelDashboard from "./Screens/DsaUser/dsaUserPanelDashboard";
import SocialMediaDashboard from "./Screens/SocialMedia/socialMediaDashboard";
function App() {
  return (
    <div className="App">
      <SuccessSnackbar />
      <ErrorSnackbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="AdminPanel/*" element={<AdminPanelDashboard />} />
        <Route
          path="SuperAdminPanel/*"
          element={<SuperAdminPanelDashboard />}
        />
        <Route path="SalesPanel/*" element={<SalesPanelDashboard />} />
        <Route path="DataEntrypanel/*" element={<DataEntryPanelDashboard />} />
        <Route path="InfraPanel/*" element={<InfraPanelDashboard />} />
        <Route
          path="CouncellorPanel/*"
          element={<CouncellorPanelDashboard />}
        />
        <Route path="TeachersPanel/*" element={<TeachersPanelDashboard />} />
        <Route path="Finance/*" element={<FinanceDashboard />} />
        <Route path="Technical/*" element={<TechnicalDashboard />} />
        <Route path="CXO/*" element={<CXODashboard />} />
        <Route path="CategoryPanel/*" element={<CategoryPanelDashboard />} />
        <Route
          path="HrOperationPanel/*"
          element={<HrOperationPanelDashboard />}
        />
        <Route path="OperationPanel/*" element={<OperationDashboard />} />
        <Route path="Configuration/*" element={<ConfigurationPanelDashboard />} />
        <Route path="DsaManager/*" element={<DsaManagerPanelDashboard />} />
        <Route path="DsaUser/*" element={<DsaUserPanelDashboard />} />
        <Route path="DsaSales/*" element={<DsaSalesPanelDashboard />} />
        <Route path="Academics/*" element={<AcademicsPanelDashboard />} />
        <Route path="Affiliate/*" element={<AffiliatePanelDashboard />} />
        <Route path="SocialMediaPanel/*" element={<SocialMediaDashboard />} />
      </Routes>
    </div>
  );
}

export default App;
