import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import {
  addExamName,
  getCountryList,
  getCourseTypelist,
  uploadExamLogo,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import {
  validateIsFileImageType,
  pdf_size_1MB,
  length_Two,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import CustomDropdown from "../../Components/customDropdown";

export default function CreateExam() {
  const [examname, setExamname] = useState<string>("");
  const [examLogo, setExamLogo] = useState<any>("");
  const [uploadExamlogo, setUploadExamlogo] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [seoExamName, setSeoExamName] = useState<string>("");

  const dispatch: Function = useDispatch();

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const postData = {
      examName: examname,
      seoExamTypeName: seoExamName,
      examLogo: uploadExamlogo,
      coursetypeId: course?._id,
    };

    dispatch(
      addExamName(
        postData,
        (res: any) => {
          setExamname("");
          setExamLogo("");
          setResetParentCourse(true);
          setResetCountryList(true);
          setSeoExamName("");
        },
        () => {}
      )
    );
  };
  const onChangeExamname = (value: any) => {
    setExamname(value);
  };
  const onChangeSeoExamname = (value: any) => {
    setSeoExamName(value);
  };
  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setExamLogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadExamLogo(
          file,
          (res: any) => {
            delete res?.filePath;
            setUploadExamlogo(res);
          },
          () => {}
        )
      );
    }
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
          // let tempStatus = res?.filter((item: any) => item?.type !== 2);
          // setCourseList(tempStatus);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: any) => {
    setCourse(value);
  };
  function validation() {
    return (
      course === "" ||
      examname === "" ||
      examname.length < length_Two - 1 ||
      seoExamName === "" ||
      seoExamName.length < length_Two - 1 ||
      examLogo === "" ||
      uploadExamlogo === ""
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"EXAM NAME CREATION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 p-2">
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter Exam Name"
                  value={examname}
                  onChangeInput={(value: any) => onChangeExamname(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={length_Two}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"SEO Exam Name"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter SEO Exam Name"
                  value={seoExamName}
                  onChangeInput={(value: any) => onChangeSeoExamname(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={length_Two}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Exam Logo"} />
              </div>
              <div className="col-6 ps-3 ">
                <ImagePickerAndViewer
                  lableName={"Option Image"}
                  value={examLogo}
                  handleChange={(e: any) => handleOptionImageChange(e)}
                  isRequired={true}
                />
              </div>
            </div>
          </div>

          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Create Exam Name"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
