import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import { useDispatch } from "react-redux";
import {
  getCountryList,
  getCourseTypelist,
  getReferenceUserList,
} from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import AdminTable from "../../Components/adminTable";
import Dropdown from "../../Components/dropdown";

export default function ReferenceUserList() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [country, setCountryCode] = useState<any>("");
  const [courseType, setCourseType] = useState<any>("");

  const [countryList, setCountryList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [counsellorReferenceList, setCounsellorReferenceList] = useState<any>(
    []
  );

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setCountryCode(value);
    setCourseType("");
    if (value) {
      const postData = {
        countryId: value?._id,
      };
      dispatch(
        getCourseTypelist(
          postData,
          (res: any) => {
            setCourseTypeList(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      refId: userId,
    };
    if (country) {
      postdata["countryId"] = country?._id;
    }
    if (courseType) {
      postdata["coursetypeId"] = courseType?._id;
    }
    dispatch(
      getReferenceUserList(
        postdata,
        (res: any) => {
          setCounsellorReferenceList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"REFERENCE USER LIST"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap  mt-3">
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Country"} />
              <div className="col-8">
                <Dropdown
                  labelName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Course"} />
              <div className="col-8">
                <Dropdown
                  labelName="Select Course"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={courseType}
                  options={courseTypeList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <Submitbtn name={"Search"} />
            </div>
          </div>
          <div className="row">
            <>
              <AdminTable
                tableData={
                  counsellorReferenceList ? counsellorReferenceList : []
                }
                pagename={"referenceUserList"}
                tableHead={[
                  "S.N",
                  "NAME",
                  "COUNTRY NAME",
                  "EMAIL",
                  "COURSETYPE NAME",
                  "PARENT NAME",
                  "PARENT EMAIL",
                  "",
                ]}
              />
            </>
          </div>
        </form>
      </div>
    </div>
  );
}
