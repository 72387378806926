import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import {
  bindSubjectList,
  deleteQuestionBank,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getLectureList,
  getQuestionBankList,
  getSubTopicList,
  getTopicList,
  updateQuestionBank,
  uploadAcademicsImg,
} from "../../Redux/Actions/AdminAction";
import {
  CodingTypeData,
  GradeList,
  QuestionType,
  complexLevel,
  complexLevelData,
} from "../../Utils/dataConstant";
import { useNavigate } from "react-router-dom";
import Addmorebtn from "../../Components/addmorebtn";
import AcademicsAnswer from "../../Components/academicsAnswer";
import TextCKEditor from "../../Components/textCKEditor";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  validateIsFileImageType,
  validateIsFileVideoType,
} from "../../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../../Utils/errorMsg";
import AcademicsOption from "../../Components/academicsOption";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import AcademicsMsqOption from "../../Components/academicsMsqOption";
import PaginationTable from "../../Components/paginationTable";
import SubmitButton from "../../Components/submitButton";
import Dropdown from "../../Components/dropdown";

export default function UpdateQuestionBank() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();

  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeEdit, setCourseTypeEdit] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examEdit, setExamEdit] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectEdit, setSubjectEdit] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [topic, setTopic] = useState<any>("");
  const [topicEdit, setTopicEdit] = useState<any>("");
  const [topicList, setTopicList] = useState([]);
  const [subTopic, setsubTopic] = useState<any>("");
  const [subTopicEdit, setSubTopicEdit] = useState<any>("");
  const [subTopicList, setsubTopicList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [gradeEdit, setGradeEdit] = useState<any>("");

  const [country, setCountry] = useState<any>("");
  const [countryEdit, setCountryEdit] = useState<any>("");
  const [countryList, setCountryList] = useState([]);

  const [lecture, setLecture] = useState<any>("");
  const [lectureEdit, setLectureEdit] = useState<any>("");
  const [lectureList, setLectureList] = useState([]);

  const [complexity, setComplexity] = useState<any>("");
  const [complexityEdit, setComplexityEdit] = useState<any>("");

  const [questionType, setQuestionType] = useState<any>("");
  const [questionTypeEdit, setQuestionTypeEdit] = useState<any>("");

  const [priorityOrder, setPriorityOrder] = useState<any>("");
  const [priorityOrderEdit, setPriorityOrderEdit] = useState<any>("");

  const [classContentArray, setClassContentArray] = useState<any>([]);
  const [optionArr, setOptionArr] = useState<any[]>([{}]);
  const [mcqHintList, setMcqHintList] = useState<any>([]);
  const [mcqAnsList, setMcqAnsList] = useState<any>([]);
  const [showFixedQue, setShowFixedQue] = useState(false);
  const [codingType, setCodingType] = useState<any>("");

  const [isEditMode, setisEditMode] = useState(false);
  const [questionList, setQuestionList] = useState<any>([]);
  const [questionId, setQuestionId] = useState<any>("");
  const [queTypeId, setQueTypeId] = useState<any>("");
  const [allEditValue, setAllEditValue] = useState<any>("");

  const initialVal: {
    descType: any;
    description: any;
    isCorrect: any;
    answerType: number;
  }[] = [
      {
        descType: "text",
        description: "",
        isCorrect: "true",
        answerType: 1,
      },
    ];
  const [fixedAnswer, setFixedAnswer] = useState<any>(initialVal);
  const config = {
    loader: { load: ["input/asciimath"] },
    asciimath: {
      displaystyle: true,
      delimiters: [
        ["$", "$"],
        ["`", "`"],
      ],
    },
  };

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCountryEdit = (value: any) => {
    setCountryEdit(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseTypeEdit = (value: { _id: any; name?: string }) => {
    setCourseTypeEdit(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGradeEdit = (value: any) => {
    setGradeEdit(value);
    const postData = {
      coursetypeId: courseTypeEdit?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeExamEdit = (value: { _id: any; name?: string }) => {
    setExamEdit(value);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseTypeEdit?._id,
      gradeId: gradeEdit?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: value._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubjectEdit = (value: { _id: any; name?: string }) => {
    setSubjectEdit(value);
    const postData = {
      coursetypeId: courseTypeEdit?._id,
      gradeId: gradeEdit?._id,
      examtypeId: examEdit?._id,
      subjectId: value._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value);
    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setsubTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeTopicEdit = (value: { _id: any; name?: string }) => {
    setTopicEdit(value);
    const data: any = {
      coursetypeId: courseTypeEdit?._id,
      gradeId: gradeEdit?._id,
      examtypeId: examEdit?._id,
      subjectId: subjectEdit?._id,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setsubTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setsubTopic(value);
    const postdata: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: topic?._id,
      subTopicId: value?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubTopicEdit = (value: { _id: any; name?: string }) => {
    setSubTopicEdit(value);
    const postdata: any = {
      coursetypeId: courseTypeEdit?._id,
      gradeId: gradeEdit?._id,
      examtypeId: examEdit?._id,
      subjectId: subjectEdit?._id,
      topicId: topicEdit?._id,
      subTopicId: value?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => { }
      )
    );
  };

  const onChangeLecture = (value: { _id: any; name?: string }) => {
    setLecture(value);
  };
  const onChangeLectureEdit = (value: { _id: any; name?: string }) => {
    setLectureEdit(value);
  };

  const onChangeComplexity = (value: any) => {
    setComplexity(value);
  };
  const onChangeComplexityEdit = (value: any) => {
    setComplexityEdit(value);
  };

  const onChangeQuestionType = (value: any) => {
    setQuestionType(value);
    setClassContentArray([]);
    setMcqAnsList([]);
    setMcqHintList([]);
    if (value?._id === 2) {
      setShowFixedQue(true);
    } else {
      setShowFixedQue(false);
    }
  };

  const onChangeQuestionTypeEdit = (value: any) => {
    setQuestionTypeEdit(value);
    setClassContentArray([]);
    setMcqAnsList([]);
    setMcqHintList([]);
    if (value?._id === 2) {
      setShowFixedQue(true);
    } else {
      setShowFixedQue(false);
    }
  };

  const onChangePriorityOrder = (value: any) => {
    setPriorityOrder(value);
  };
  const onChangePriorityOrderEdit = (value: any) => {
    setPriorityOrderEdit(value);
  };

  const showInputMenu = (inputType: any) => {
    const newArrr = [...classContentArray];
    const index = newArrr?.length || 0;
    const newItem = {
      descType: inputType,
      description: "",
      contentOrder: index + 1,
    };
    newArrr.push(newItem);
    setClassContentArray(newArrr);
  };

  const onPressAdd = () => {
    if (optionArr.length < 4) setOptionArr([...optionArr, {}]);
  };

  const storeMcqHint = (val: any) => {
    setMcqHintList(val);
  };
  const storeMcqAnswer = (val: any) => {
    setMcqAnsList(val);
  };

  const onChangeClassContentText = (index: any, value: any, key: string) => {
    const newArrr = [...classContentArray];
    if (key) {
      newArrr[index][key] = value;
      setClassContentArray(newArrr);
    }
  };

  const onChangeClassContentImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeClassContentText(index, res.filePath, "description");
          },
          () => { }
        )
      );
    }
  };

  const onChangeClassContentVideo = (e: any, index: any) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => { };
    reader.readAsDataURL(file);

    dispatch(
      uploadAcademicsImg(
        file,
        (res: any) => {
          if (res) {
            dispatch(showSuccessSnackbar("File Succesfully Uploaded") as any);
            onChangeClassContentText(index, res.filePath, "description");
          } else {
            dispatch(showErrorSnackbar("File Not Uploaded") as any);
          }
        },
        () => { }
      )
    );
  };

  const handleCloseClassContent = (index: any) => {
    const newArrr = [...classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setClassContentArray(newArrr);
  };
  const renderClassContent = () => {
    return classContentArray?.map((item: any, index: any) => {
      const { descType, description } = item;
      return (
        <>
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            {descType === "text" ? (
              <div className="m-2 w-100">
                <TextCKEditor
                  value={description}
                  onChange={(value: any) =>
                    onChangeClassContentText(index, value, "description")
                  }
                />
              </div>
            ) : null}
            {descType === "image" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Image"}
                  value={description}
                  handleChange={(e: any) => onChangeClassContentImg(e, index)}
                  removelabel={true}
                />
              </div>
            ) : null}
            {descType === "video" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Video"}
                  value={description}
                  handleChange={(value: any) =>
                    onChangeClassContentVideo(value, index)
                  }
                  doctype={"video"}
                />
              </div>
            ) : null}
            <div>
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseClassContent(index)}
                />
              </span>
            </div>
          </div>
        </>
      );
    });
  };
  const handleOption = (value: {
    descType: string;
    description: string;
    isCorrect: boolean;
    index: number;
  }) => {
    const item = {
      descType: value.descType,
      description: value.description,
      isCorrect: value.isCorrect,
    };
    optionArr[value.index] = item;
  };

  const handleCorrectOption = (value: {
    index: number;
    isCorrect: boolean;
  }) => {
    let newArray = JSON.parse(JSON.stringify(optionArr));
    newArray?.forEach((item: any) => {
      item.isCorrect = false;
    });
    newArray[value.index].isCorrect = value.isCorrect;
    setOptionArr(newArray);
  };

  const handleClose = (index: any) => {
    const newArrr = [...optionArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setOptionArr(newArrr);
  };

  const renderOption = () => {
    return optionArr.map((item, index) => {
      return (
        <>
          <div className="position-relative d-flex justify-content-center p-2">
            <div className="w-100">
              <AcademicsOption
                index={index}
                option={item}
                setOptionData={(value: {
                  descType: string;
                  description: string;
                  isCorrect: boolean;
                  index: number;
                }) => handleOption(value)}
                setCorrectOption={(value: {
                  index: number;
                  isCorrect: boolean;
                }) => handleCorrectOption(value)}
                getData={""}
              />
            </div>
            {optionArr.length === 1 ? null : (
              <span className="text-dark d-flex">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleClose(index)}
                />
              </span>
            )}
          </div>
        </>
      );
    });
  };

  const onChangeFixedAnswer = (index: any, value: any, key: string) => {
    const newArrr = [...fixedAnswer];
    if (key) {
      newArrr[index][key] = value;
      setFixedAnswer(newArrr);
    }
  };

  const handleCorrectMsqOption = (value: any) => {
    let newArray = JSON.parse(JSON.stringify(optionArr));
    if (value?.isCorrect) {
      newArray[value?.index].isCorrect = value?.isCorrect;
      setOptionArr(newArray);
    } else {
      newArray[value?.index].isCorrect = value?.isCorrect;
      setOptionArr(newArray);
    }
  };

  const renderMsqOption = () => {
    return optionArr.map((item, index) => {
      return (
        <>
          <div className="position-relative d-flex justify-content-center p-2">
            <div className="w-100">
              <AcademicsMsqOption
                index={index}
                option={item}
                setOptionData={(value: {
                  descType: string;
                  description: string;
                  isCorrect: boolean;
                  index: number;
                }) => handleOption(value)}
                setCorrectOption={(value: {
                  index: number;
                  isCorrect: boolean;
                }) => handleCorrectMsqOption(value)}
                getData={""}
              />
            </div>
            {optionArr.length === 1 ? null : (
              <span className="text-dark d-flex">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleClose(index)}
                />
              </span>
            )}
          </div>
        </>
      );
    });
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setQueTypeId(value?.questionType?._id);
    setQuestionId(value?.questionId);
    setAllEditValue(value);
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
          let tempCountry = res?.filter(
            (item: any) => value?.country?._id === item?._id
          );
          setCountryEdit(tempCountry[0]);
        },
        () => { }
      )
    );
    const postData = {
      countryId: value?.country?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
          let tempCourse = res?.filter(
            (item: any) => value?.course?._id === item?._id
          );
          setCourseTypeEdit(tempCourse[0]);
        },
        () => { }
      )
    );

    dispatch(
      getGeneralExamList(
        {
          courseId: value?.course?._id,
        },
        (res: any) => {
          setExamList(res);
          let tempExam = res?.filter(
            (item: any) => value?.exam?._id === item?._id
          );
          setExamEdit(tempExam[0]);
        },
        () => { }
      )
    );

    const postdata: any = {
      examtypeId: value?.exam?._id,
      coursetypeId: value?.course?._id,
      gradeId: value?.grade?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postdata,
        (res: any) => {
          setSubjectList(res);
          let tempSubject = res?.filter(
            (item: any) => value?.subject?._id === item?._id
          );
          setSubjectEdit(tempSubject[0]);
        },
        () => { }
      )
    );

    const Data = {
      coursetypeId: value?.course?._id,
      gradeId: value?.grade?._id,
      examtypeId: value?.exam?._id,
      subjectId: value?.subject?._id,
    };
    if (
      Data["gradeId"] === null ||
      Data["gradeId"] === undefined ||
      Data["gradeId"] === ""
    ) {
      delete Data["gradeId"];
    }
    if (
      Data["examtypeId"] === null ||
      Data["examtypeId"] === undefined ||
      Data["examtypeId"] === ""
    ) {
      delete Data["examtypeId"];
    }

    dispatch(
      getTopicList(
        Data,
        (res: any) => {
          setTopicList(res);
          let tempTopic = res?.filter(
            (item: any) => value?.topic?._id === item?._id
          );
          setTopicEdit(tempTopic[0]);
        },
        () => { }
      )
    );

    const data: any = {
      coursetypeId: value?.course?._id,
      gradeId: value?.grade?._id,
      examtypeId: value?.exam?._id,
      subjectId: value?.subject?._id,
      topicId: value?.topic?._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setsubTopicList(res);
          let tempSubTopic = res?.filter(
            (item: any) => value?.subTopic?._id === item?._id
          );
          setSubTopicEdit(tempSubTopic[0]);
        },
        () => { }
      )
    );

    const postValue: any = {
      coursetypeId: value?.course?._id,
      gradeId: value?.grade?._id,
      examtypeId: value?.exam?._id,
      subjectId: value?.subject?._id,
      topicId: value?.topic?._id,
      subTopicId: value?.subTopic?._id,
    };
    if (
      postValue["gradeId"] === null ||
      postValue["gradeId"] === undefined ||
      postValue["gradeId"] === ""
    ) {
      delete postValue["gradeId"];
    }
    if (
      postValue["examtypeId"] === null ||
      postValue["examtypeId"] === undefined ||
      postValue["examtypeId"] === ""
    ) {
      delete postValue["examtypeId"];
    }
    if (
      postValue["levelId"] === null ||
      postValue["levelId"] === undefined ||
      postValue["levelId"] === ""
    ) {
      delete postValue["levelId"];
    }
    if (
      postValue["topicId"] === null ||
      postValue["topicId"] === undefined ||
      postValue["topicId"] === ""
    ) {
      delete postValue["topicId"];
    }
    if (
      postValue["subTopicId"] === null ||
      postValue["subTopicId"] === undefined ||
      postValue["subTopicId"] === ""
    ) {
      delete postValue["subTopicId"];
    }
    dispatch(
      getLectureList(
        postValue,
        (res: any) => {
          setLectureList(res);
          let tempLecture = res?.filter(
            (item: any) => value?.lecture?._id === item?._id
          );
          setLectureEdit(tempLecture[0]);
        },
        () => { }
      )
    );

    let tempComplexLevel: any = complexLevel?.filter(
      (item: any) => value?.complexityLevel?._id === item?._id
    );
    setComplexityEdit(tempComplexLevel[0]);

    let tempQuestionType: any = QuestionType?.filter(
      (item: any) => value?.questionType?._id === item?._id
    );
    setQuestionTypeEdit(tempQuestionType[0]);

    let tempPriorityOrder: any = complexLevelData?.filter(
      (item: any) => value?.priorityLevel?._id === item?._id
    );
    setPriorityOrderEdit(tempPriorityOrder[0]);

    setGradeEdit(value?.grade?._id);

    if (value?.questionType?._id === 1 || value?.questionType?._id === 3) {
      setClassContentArray(value?.question);
      setOptionArr(value?.option);
      setMcqAnsList(value?.answer);
    }
    if (value?.questionType?._id === 2) {
      setClassContentArray(value?.question);
      setFixedAnswer(value?.option);
      setMcqAnsList(value?.answer);
    }
    if (value?.questionType?._id === 4 || value?.questionType?._id === 6) {
      setClassContentArray(value?.question);
      setMcqAnsList(value?.answer);
    }
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteQuestionBank(
        value?.questionId,
        () => {
          const postData: any = {
            countryId: country?._id,
            coursetypeId: courseType?._id,
            examtypeId: exam?._id,
            gradeId: grade?._id,
            subjectId: subject?._id,
            topicId: topic?._id,
            subTopicId: subTopic?._id,
            lectureId: lecture?._id,
            type: questionType?._id,
            complexitylevelId: complexity?._id,
            prioritylevelId: priorityOrder?._id,
          };
          if (
            postData["gradeId"] === null ||
            postData["gradeId"] === undefined ||
            postData["gradeeId"] === ""
          ) {
            delete postData["gradeId"];
          }
          dispatch(
            getQuestionBankList(
              postData,
              (res: any) => {
                setQuestionList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };

  const onClickSearch = (value: any) => {
    const postData: any = {
      countryId: country?._id,
      coursetypeId: courseType?._id,
      examtypeId: exam?._id,
      gradeId: grade?._id,
      subjectId: subject?._id,
      topicId: topic?._id,
      subTopicId: subTopic?._id,
      lectureId: lecture?._id,
      type: questionType?._id,
      complexitylevelId: complexity?._id,
      prioritylevelId: priorityOrder?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getQuestionBankList(
        postData,
        (res: any) => {
          setQuestionList(res);
        },
        () => { }
      )
    );
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata: any = {
      countryId: countryEdit?._id,
      coursetypeId: courseTypeEdit?._id,
      examtypeId: examEdit?._id,
      gradeId: gradeEdit?._id,
      subjectId: subjectEdit?._id,
      topicId: topicEdit?._id,
      subTopicId: subTopicEdit?._id,
      lectureId: lectureEdit?._id,
      complexitylevelId: complexityEdit?._id,
      prioritylevelId: priorityOrderEdit?._id,
      type: questionTypeEdit?._id,
      codingLanguage: codingType?._id,
      questionId: questionId,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["codingLanguage"] === null ||
      postdata["codingLanguage"] === undefined ||
      postdata["codingLanguage"] === ""
    ) {
      delete postdata["codingLanguage"];
    }
    if (queTypeId === 1 || queTypeId === 3) {
      postdata["question"] = classContentArray;
      postdata["option"] = optionArr;
      postdata["answer"] = mcqAnsList?.answer;
    }
    if (queTypeId === 2) {
      postdata["question"] = classContentArray;
      postdata["option"] = fixedAnswer;
      postdata["answer"] = mcqAnsList?.answer;
    }
    if (queTypeId === 4 || queTypeId === 6) {
      postdata["question"] = classContentArray;
      postdata["answer"] = mcqAnsList?.answer;
    }
    if (
      postdata["complexitylevelId"] === null ||
      postdata["complexitylevelId"] === undefined ||
      postdata["complexitylevelId"] === ""
    ) {
      delete postdata["complexitylevelId"];
    }
    if (
      postdata["prioritylevelId"] === null ||
      postdata["prioritylevelId"] === undefined ||
      postdata["prioritylevelId"] === ""
    ) {
      delete postdata["prioritylevelId"];
    }

    dispatch(
      updateQuestionBank(
        postdata,
        (res: any) => {
          setisEditMode(false);
          const postData: any = {
            countryId: country?._id,
            coursetypeId: courseType?._id,
            examtypeId: exam?._id,
            gradeId: grade?._id,
            subjectId: subject?._id,
            topicId: topic?._id,
            subTopicId: subTopic?._id,
            lectureId: lecture?._id,
            type: questionType?._id,
            complexitylevelId: complexity?._id,
            prioritylevelId: priorityOrder?._id,
          };
          if (
            postData["gradeId"] === null ||
            postData["gradeId"] === undefined ||
            postData["gradeeId"] === ""
          ) {
            delete postData["gradeId"];
          }
          dispatch(
            getQuestionBankList(
              postData,
              (res: any) => {
                setQuestionList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <div className="p-2">
        <div className="border mt-4 ">
          <div className="d-flex flex-wrap justify-content-between p-3">
            <div className="col-md-12 d-flex flex-wrap ">
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Country"} isRequired />
                </div>
                <div className="col-7 ps-2 ">
                  <Dropdown
                    labelName="Select Country"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={country}
                    options={countryList}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} isRequired />
                </div>
                <div className="col-7 ps-2  ">
                  <Dropdown
                    labelName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                  />
                </div>
              </div>
              {courseType?.type === 2 ? (
                <div className="d-flex col-md-4 Justify-content-between">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Student Grade"} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Student Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      options={GradeList}
                    />
                  </div>
                </div>
              ) : null}
              {courseType?.type === 2 || courseType?.type === 3 ? null : (
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={exam}
                      options={examList}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 d-flex flex-wrap justify-content-between mt-2">
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} isRequired />
                </div>
                <div className="col-7 ps-2 ">
                  <Dropdown
                    labelName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Topic Name"} isRequired />
                </div>
                <div className="col-7 ps-2 ">
                  <Dropdown
                    labelName="Select Topic Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeTopic(value)
                    }
                    value={topic}
                    options={topicList}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Sub-Topic Name"} isRequired />
                </div>
                <div className="col-7 ps-2 ">
                  <Dropdown
                    labelName="Select Sub-Topic Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubTopic(value)
                    }
                    value={subTopic}
                    options={subTopicList}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 d-flex flex-wrap justify-content-between">
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Lecture Name "} isRequired />
                </div>
                <div className="col-7 ps-2 ">
                  <Dropdown
                    labelName="Select Lecture Name "
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeLecture(value)
                    }
                    value={lecture}
                    options={lectureList}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Complexity Level"} />
                </div>
                <div className="col-7 ps-2">
                  <Dropdown
                    labelName="Select Complexity Level"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeComplexity(value)
                    }
                    value={complexity}
                    options={complexLevel}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Question Type"} />
                </div>
                <div className="col-7 ps-2">
                  <Dropdown
                    labelName="Select Question Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeQuestionType(value)
                    }
                    value={questionType}
                    options={QuestionType}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 d-flex flex-wrap  mt-1">
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Priority Order"} />
                </div>
                <div className="col-7 ps-2">
                  <Dropdown
                    labelName="Select Priority Order"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangePriorityOrder(value)
                    }
                    value={priorityOrder}
                    options={complexLevelData}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <button
                  type="button"
                  className="btn btn-dark mt-2"
                  onClick={(value) => onClickSearch(value)}
                  disabled={
                    country === "" ||
                    courseType === "" ||
                    subject === "" ||
                    topic === "" ||
                    subTopic === "" ||
                    lecture === ""
                  }
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="px-4 mx-0 formDiv ">
        <form onSubmit={handleSubmit}>
          <div className="border mt-4 ">
            <div className="d-flex flex-wrap justify-content-between p-3">
              <div className="col-md-12 d-flex flex-wrap justify-content-between">
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Country"} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Country"
                      setInputText={(value: any) => onChangeCountryEdit(value)}
                      value={countryEdit}
                      options={countryList}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Course Type"} isRequired />
                  </div>
                  <div className="col-7 ps-2  ">
                    <Dropdown
                      labelName="Select Course Type"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeCourseTypeEdit(value)
                      }
                      value={courseTypeEdit}
                      options={courseTypeList}
                    />
                  </div>
                </div>
                {courseTypeEdit?.type === 2 ? (
                  <div className="d-flex col-md-4 Justify-content-between">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Student Grade"} isRequired />
                    </div>
                    <div className="col-7 ps-2 ">
                      <Dropdown
                        labelName="Select Student Grade"
                        setInputText={(value: any) =>
                          onChangeStudentGradeEdit(value)
                        }
                        value={gradeEdit}
                        options={GradeList}
                      />
                    </div>
                  </div>
                ) : null}
                {courseTypeEdit?.type === 2 ||
                  courseTypeEdit?.type === 3 ? null : (
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Exam Name"} isRequired />
                    </div>
                    <div className="col-7 ps-2 ">
                      <Dropdown
                        labelName="Select Exam Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeExamEdit(value)
                        }
                        value={examEdit}
                        options={examList}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12 d-flex flex-wrap justify-content-between mt-2">
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Subject Name"} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Subject Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeSubjectEdit(value)
                      }
                      value={subjectEdit}
                      options={subjectList}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Topic Name"} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Topic Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeTopicEdit(value)
                      }
                      value={topicEdit}
                      options={topicList}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Sub-Topic Name"} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Sub-Topic Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeSubTopicEdit(value)
                      }
                      value={subTopicEdit}
                      options={subTopicList}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 d-flex flex-wrap justify-content-between">
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Lecture Name "} isRequired />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Lecture Name "
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeLectureEdit(value)
                      }
                      value={lectureEdit}
                      options={lectureList}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Complexity Level"} />
                  </div>
                  <div className="col-7 ps-2">
                    <Dropdown
                      labelName="Select Complexity Level"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeComplexityEdit(value)
                      }
                      value={complexityEdit}
                      options={complexLevel}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Question Type"} />
                  </div>
                  <div className="col-7 ps-2">
                    <Dropdown
                      labelName="Select Question Type"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeQuestionTypeEdit(value)
                      }
                      value={questionTypeEdit}
                      options={QuestionType}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 d-flex flex-wrap  mt-1">
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Priority Order"} />
                  </div>
                  <div className="col-7 ps-2">
                    <Dropdown
                      labelName="Select Priority Order"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangePriorityOrderEdit(value)
                      }
                      value={priorityOrderEdit}
                      options={complexLevelData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {queTypeId === 1 ? (
            <>
              <div className="mt-4 border border-black">
                <div className="m-2 border border-black">
                  <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-2 mx-2">
                    <h5 className="fw-bold text-light">Question Data</h5>
                    <div className=" d-flex">
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("text")}
                      >
                        + Text
                      </div>
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("image")}
                      >
                        + Image
                      </div>
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("video")}
                      >
                        + Video
                      </div>
                    </div>
                  </div>
                  {renderClassContent()}

                  <div className="mx-2 mb-2 border">
                    <div>{renderOption()}</div>
                    {optionArr.length < 4 ? (
                      <div className="d-flex justify-content-end m-2">
                        <Addmorebtn
                          name="Add New Option"
                          handleClick={onPressAdd}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="m-2 border border-black">
                  <AcademicsAnswer
                    setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                    getData={allEditValue?.answer}
                    pageName="UpdateQuestionBank"
                  />
                </div>
              </div>
            </>
          ) : null}

          {queTypeId === 2 || queTypeId === 4 || queTypeId === 6 ? (
            <>
              {queTypeId === 6 ? (
                <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-4">
                  <div className="d-flex">
                    <h5 className="fw-bold text-light"></h5>
                    {queTypeId === 6 ? (
                      <div className="d-flex align-items-center">
                        <span className="position-relative addInputbtn">
                          <div className="cursor ms-2 p-1 col-md-12">
                            {codingType
                              ? codingType?.name
                              : "+ Select Coding Type"}
                          </div>

                          <div className="position-absolute notiDivPractice">
                            <div className="position-absolute transperentbg"></div>
                            <div
                              className="position-absolute border p-1 font12"
                              style={{
                                background: "#fff",
                                top: "2px",
                                right: "29px",
                                width: "160px",
                                boxShadow: "black -2px 2px 10px -2px",
                              }}
                            >
                              <div className="px-0">
                                {CodingTypeData?.map((c: any) => (
                                  <div
                                    className="p-2 addQuestionType"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setCodingType(c)}
                                  >
                                    <span className="ms-3 colorgray ">
                                      {c?.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="mt-2 border border-black">
                <div className="m-2 border border-black">
                  <div className="p-3 bg-dark d-flex align-items-center justify-content-between  mx-2 my-2">
                    <h5 className="fw-bold text-light">Question Data</h5>
                    <div className=" d-flex">
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("text")}
                      >
                        + Text
                      </div>
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("image")}
                      >
                        + Image
                      </div>
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("video")}
                      >
                        + Video
                      </div>
                    </div>
                  </div>
                  {renderClassContent()}
                </div>
                <></>
                {showFixedQue &&
                  fixedAnswer?.map((item: any, index: any) => {
                    const { descType, description, answerType } = item;
                    return (
                      <div className="m-2 border border-black">
                        <div
                          key={index}
                          className={`mb-1 px-3 py-2 border rounded`}
                        >
                          <label className="fw-bold mb-2">Answer</label>
                          <div className="d-flex mb-2">
                            <div className="me-3">
                              <input
                                type="radio"
                                name="fixedInput"
                                onChange={(e: any) =>
                                  onChangeFixedAnswer(index, 1, "answerType")
                                }
                                checked={answerType === 1}
                              />{" "}
                              <span>Text</span>
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="fixedInput"
                                onChange={(e: any) =>
                                  onChangeFixedAnswer(index, 2, "answerType")
                                }
                                checked={answerType === 2}
                              />{" "}
                              <span>Equation</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div
                              className="col-md-6"
                              style={{ marginLeft: "1px" }}
                            >
                              <textarea
                                className="form-control cursor"
                                value={description}
                                onChange={(e: any) =>
                                  onChangeFixedAnswer(
                                    index,
                                    e.target.value,
                                    "description"
                                  )
                                }
                                style={{ height: "100px" }}
                              />
                            </div>
                            <div
                              className="col-md-6"
                              style={{ marginLeft: "5px" }}
                            >
                              {answerType === 2 && description ? (
                                <MathJaxContext config={config}>
                                  <MathJax dynamic>
                                    <h6
                                      className="d-flex align-items-center justify-content-center border rounded"
                                      style={{
                                        height: "100px",
                                        overflow: "auto",
                                      }}
                                    >{`$${description}$`}</h6>
                                  </MathJax>{" "}
                                </MathJaxContext>
                              ) : (
                                <div>
                                  <h6
                                    className="d-flex align-items-center justify-content-center border rounded"
                                    style={{
                                      height: "100px",
                                      overflow: "auto",
                                    }}
                                  >{`${description}`}</h6>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="m-2 border border-black">
                  <AcademicsAnswer
                    setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                    getData={allEditValue?.answer}
                    pageName="UpdateQuestionBank"
                  />
                </div>
              </div>
            </>
          ) : null}

          {queTypeId === 3 ? (
            <>
              <div className="mt-2 border border-black">
                <div className="m-2 border border-black">
                  <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-2 mx-2">
                    <h5 className="fw-bold text-light">Question Data</h5>
                    <div className=" d-flex">
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("text")}
                      >
                        + Text
                      </div>
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("image")}
                      >
                        + Image
                      </div>
                      <div
                        className="cursor addInputbtn ms-2 p-1"
                        onClick={(id: any) => showInputMenu("video")}
                      >
                        + Video
                      </div>
                    </div>
                  </div>
                  {renderClassContent()}

                  <div className="mx-2 mb-2 border">
                    <div>{renderMsqOption()}</div>
                    {optionArr.length < 4 ? (
                      <div className="d-flex justify-content-end m-2">
                        <Addmorebtn
                          name="Add New Option"
                          handleClick={onPressAdd}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="m-2 border border-black">
                  <AcademicsAnswer
                    setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                    getData={allEditValue?.answer}
                    pageName="UpdateQuestionBank"
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className=" d-flex justify-content-center mt-3">
            <SubmitButton name={"Update"} />
          </div>
        </form>
      </div>
    );
  };
  const renderListMode = () => {
    return (
      <div className="">
        <PaginationTable
          tableData={questionList ? questionList : []}
          pagename={"updateQuestionBank"}
          tableHead={["S.N", "QUESTION TYPE", "QUESTION", "ACTION"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT QUESTION BANK"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {!isEditMode ? renderSearchMode() : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
