import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import Addmorebtn from "../../Components/addmorebtn";
import DeleteIcon from "@mui/icons-material/Delete";
import { addSubject } from "../../Redux/Actions/AdminAction";
import { length_Two, length_TwoHundredFifty } from "../../Utils/validators";

export default function CreateSubject() {
  const dispatch: Function = useDispatch();
  const initialVal = "";
  const [subjectArr, setSubjectArr] = useState([initialVal]);
  const [seoSubjectName, setSeoSubjectName] = useState("");

  useEffect(() => {}, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      name: subjectArr,
      seoSubjectName: seoSubjectName,
    };
    dispatch(
      addSubject(
        Postdata,
        (res: any) => {
          setSubjectArr([initialVal]);
        },
        () => {}
      )
    );
  };

  const onChangeSeoSubjectname = (value: any) => {
    setSeoSubjectName(value);
  };

  const onPressAdd = () => {
    setSubjectArr([...subjectArr, initialVal[0]]);
  };
  const handleClose = (index: any) => {
    const newArrr = [...subjectArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setSubjectArr(newArrr);
  };

  function validation() {
    let isSubjectArr: any = false;
    subjectArr?.map((item) => {
      if (
        item === "" ||
        item === null ||
        item === undefined ||
        item.length < length_Two + 1
      ) {
        isSubjectArr = true;
      }
    });
    return isSubjectArr === true || seoSubjectName === "";
  }
  function onchangeSubject(val: any, index: any) {
    let newArr = JSON.parse(JSON.stringify(subjectArr));
    newArr[index] = val;
    setSubjectArr(newArr);
  }
  return (
    <div className="contentBox">
      <PageTitle name={"CREATE SUBJECT"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 mt-3">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-4 border p-3">
                {subjectArr.map((item, index) => {
                  return (
                    <div className=" d-flex">
                      <div className="col-8">
                        <InputField
                          placeholder="Enter Subject Name"
                          value={item}
                          onChangeInput={(value: any) =>
                            onchangeSubject(value, index)
                          }
                          maxlength={length_TwoHundredFifty - 1}
                          minlength={length_Two + 1}
                        />
                      </div>

                      {subjectArr.length === 1 ? null : (
                        <DeleteIcon
                          className="cursor"
                          color="error"
                          fontSize="small"
                          onClick={() => handleClose(index)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-center ms-2">
                <Addmorebtn name="Add More" handleClick={onPressAdd} />
              </div>
            </div>
            <div className="col-11 d-flex Justify-content-between mt-3">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"SEO Subject Name"} />
              </div>
              <div className="col-4 ps-3 ">
                <InputField
                  placeholder="Enter SEO Subject Name"
                  value={seoSubjectName}
                  onChangeInput={(value: any) => onChangeSeoSubjectname(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={length_Two}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Create Subject"} validation={validation()} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
