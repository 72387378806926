import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import localImages from "../../Constant/localImages";
;
export default function TutorDataDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { details } = location.state;
  console.log("details", details);
  
  useEffect(() => {}, []);

  const renderListMode = () => {
    return (
      <div className="border mt-5 p-3">
        <h4 className="fw-bold">Tutor Personal Details</h4>
        <div className="row mt-2">
          <div className="row d-flex justify-content-between">
            {details?.name ? (
              <div className=" col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Full Name:</div>
                <div className="font16 mb-2 ms-2">{details?.name}</div>
              </div>
            ) : null}

            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Personal Mobile number:</div>
              <div className="font16 mb-2 ms-2">{details?.mobile}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Aadhaar card number:</div>
              <div className="font16 mb-2 ms-2">
                {details?.aadhaar_card_number}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Personal Mail ID:</div>
              <div className="font16 mb-2 ms-2">{details?.email}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Gender:</div>
              <div className="font16 mb-2 ms-2"> {details?.gender}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Date of Birth:</div>
              <div className="font16 mb-2 ms-2">
                {moment(details?.date_of_birth).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Blood Group:</div>
              <div className="font16 mb-2 ms-2">{details?.blood_group}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Father Name:</div>
              <div className="font16 mb-2 ms-2">{details?.father_name}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Marital Status:</div>
              <div className="font16 mb-2 ms-2">{details?.marital_status}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Place of birth:</div>
              <div className="font16 mb-2 ms-2">{details?.place_of_birth}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Aadhaar card number:</div>
              <div className="font16 mb-2 ms-2">
                {details?.aadhaar_card_number}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Pan card number:</div>
              <div className="font16 mb-2 ms-2">{details?.pan_card_number}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Qualification:</div>
              <div className="font16 mb-2 ms-2">{details?.qualification}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">
                College/University Name:
              </div>
              <div className="font16 mb-2 ms-2">
                {details?.college_university_name}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Date of Joining:</div>
              <div className="font16 mb-2 ms-2">
                {moment(details?.date_of_joining).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Grade:</div>
              <div className="font16 mb-2 ms-2">{details?.grade}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Department:</div>
              <div className="font16 mb-2 ms-2">{details?.department}</div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Permanent Address:</div>
              <div className="font16 mb-2 ms-2">
                {details?.permanent_address}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Emergency Contact Name:</div>
              <div className="font16 mb-2 ms-2">
                {details?.emergency_contact_name}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">
                Emergency Contact Number:
              </div>
              <div className=" font16 mb-2 ms-2">
                {details?.emergency_contact_number}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">
                Emergency Contact Relationship:
              </div>
              <div className="font16 mb-2 ms-2">
                {details?.emergency_contact_number}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">Bank account number:</div>
              <div className="font16 mb-2 ms-2">
                {details?.bank_account_number}
              </div>
            </div>
            <div className="col-md-4 d-flex p-2">
              <div className="font16 mb-2 fw-bold">IFSC Code:</div>
              <div className="font16 mb-2 ms-2">{details?.ifsc_code}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDocument = () => {
    return (
      <>
      <h4 className="fw-bold">Tutor Document</h4>
      <div className="row d-flex justify-content-between">
    
        <div className="col-md-3 p-5 text-center">
         
          <img src={details?.photo_id_card} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Tutor Photo</p>
       
         
        </div>
        <div className="col-md-3 p-5 text-center">
       
         <img src={details?.adhar_card} className="img-fluid"/>
         <p className="mt-2 font16 fw-bold text-dark ">Adhar Card</p>
    
        
       </div>
       <div className="col-md-3 p-5 text-center">
        
          <img src={details?.pan_card} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Pan Card</p>
         
         
        </div>
        <div className="col-md-3 p-5 text-center ">
        <a href = {details?.offer_letter} target = "_blank" className="tutorDocumentLabel"> 
          <img src={localImages?.pdfimage} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Offer Letter</p>
        </a>
         
        </div>

        <div className="col-md-3 p-5 text-center  ">
        <a href = {details?.tutor_policy} target = "_blank" className="tutorDocumentLabel" > 
          <img src={localImages?.pdfimage} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark "> Tuto Policy</p>
        </a>
         
        </div>
        <div className="col-md-3 p-5 text-center">
        <a href = {details?.form_11} target = "_blank" className="tutorDocumentLabel"> 
          <img src={localImages?.pdfimage} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Form 11</p>
        </a>
         
        </div>
        <div className="col-md-3 p-5 text-center">
        <a href = {details?.declaration_form} target = "_blank" className="tutorDocumentLabel"> 
          <img src={localImages?.pdfimage} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Declaration form</p>
        </a>
         
        </div>
        <div className="col-md-3 p-5 text-center">
        <a href = {details?.asset_declaration} target = "_blank" className="tutorDocumentLabel"> 
          <img src={localImages?.pdfimage} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Assets Declaration</p>
        </a>
         
        </div>
        <div className="col-md-3 p-5 text-center">
        <a href = {details?.data_protection_form} target = "_blank" className="tutorDocumentLabel"> 
          <img src={localImages?.pdfimage} className="img-fluid"/>
          <p className="mt-2 font16 fw-bold text-dark ">Data Protection Form</p>
        </a>
         
        </div>
      </div></>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"TUTOR DETAILS"} />
      <div className="formDiv">
      <div className="px-4 mx-0 mt-4  ">{renderListMode()}</div>
      <div className="px-4 mx-0 mt-4  ">{renderDocument()}</div>
    </div></div>
  );
}
