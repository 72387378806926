import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import AdminTable from "../../Components/adminTable";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import {
  deleteCoupon,
  getCountryList,
  getCouponList,
  getCourseTypelist,
  updateCoupon,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsNumberOnly,
  length_Two,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import CustomDropdown from "../../Components/customDropdown";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import { CouponStatusList, TypeList } from "../../Utils/dataConstant";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import Dropdown from "../../Components/dropdown";

export default function UpdateCouponCode() {
  const dispatch: Function = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>();
  const [isCounsellorEdit, setIsCounsellorEdit] = useState<boolean>();
  const [couponName, setCouponName] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [startDateEdit, setStartDateEdit] = useState<any>("");
  const [mindate, setmindate] = useState("");
  const [couponList, setCouponList] = useState<any>([]);
  const [type, setType] = useState<any>("");
  const [resetTypeList, setResetTypeList] = useState(false);
  const [discPercent, setDiscPercent] = useState<any>("");
  const [toggle, setToggle] = useState<any>(false);
  const [couponId, setCouponId] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [couponStatus, setCouponStatus] = useState<any>("");
  const [resetCouponStatusList, setResetCouponStatusList] = useState(false);
  const [allValue, setAllValue] = useState<any>("");

  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));
  }, []);

  const onChangeType = (value: any) => {
    setResetTypeList(false);
    setType(value);
  };

  const onChangeCouponStatus = (value: any) => {
    setResetCouponStatusList(false);
    setCouponStatus(value);
  };

  const onchangeCouponName = (value: any) => {
    setCouponName(value);
  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
  };

  const onchangeDiscPercent = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setDiscPercent(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const onChange = () => {
    const postdata = {
      couponName: couponName,
      startDate: startDate,
      endDate: endDate,
      discPercentage: discPercent,
      couponType: type?._id,
      status: couponStatus?._id,
    };
    if (
      postdata["couponName"] === null ||
      postdata["couponName"] === undefined ||
      postdata["couponName"] === ""
    ) {
      delete postdata["couponName"];
    }
    if (
      postdata["discPercentage"] === null ||
      postdata["discPercentage"] === undefined ||
      postdata["discPercentage"] === ""
    ) {
      delete postdata["discPercentage"];
    }
    if (
      postdata["startDate"] === null ||
      postdata["startDate"] === undefined ||
      postdata["startDate"] === ""
    ) {
      delete postdata["startDate"];
    }
    if (
      postdata["endDate"] === null ||
      postdata["endDate"] === undefined ||
      postdata["endDate"] === ""
    ) {
      delete postdata["endDate"];
    }
    dispatch(
      getCouponList(
        postdata,
        (res: any) => {
          setCouponList(res);
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setIsEdit(true);

    setCouponId(value?.couponId);
    setType(value?.couponType);
    setCouponName(value?.couponName);
    setDiscPercent(value?.discPercentage);
    setStartDate(value?.startDate);
    setStartDateEdit(value?.startDate);
    setEndDate(value?.endDate);
    setToggle(value?.isActive);
    if (type?._id === 2) {
      setIsEdit(true);
      setIsCounsellorEdit(true);
      setDiscPercent(value?.discPercentage);
      setStartDate(value?.startDate);
      setStartDateEdit(value?.startDate);
      setEndDate(value?.endDate);
      dispatch(
        getCountryList(
          "",
          (res: any) => {
            setCountryList(res);
            let tempCountry = res?.filter(
              (item: any) => parseInt(value?.countryId) === item?._id
            );
            setCountry(tempCountry[0]);
          },
          () => {}
        )
      );
      const postData = {
        countryId: parseInt(value?.countryId),
      };
      dispatch(
        getCourseTypelist(
          postData,
          (res: any) => {
            setCourseList(res);
            let tempCourse = res?.filter(
              (item: any) => parseInt(value?.coursetypeId) === item?._id
            );
            setCourse(tempCourse[0]);
          },
          () => {}
        )
      );
    }
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteCoupon(
        value.couponId,
        () => {
          const postdata = {
            couponName: couponName,
            startDate: startDate,
            endDate: endDate,
            couponType: type?._id,
          };
          if (
            postdata["couponName"] === null ||
            postdata["couponName"] === undefined ||
            postdata["couponName"] === ""
          ) {
            delete postdata["couponName"];
          }
          if (
            postdata["startDate"] === null ||
            postdata["startDate"] === undefined ||
            postdata["startDate"] === ""
          ) {
            delete postdata["startDate"];
          }
          if (
            postdata["endDate"] === null ||
            postdata["endDate"] === undefined ||
            postdata["endDate"] === ""
          ) {
            delete postdata["endDate"];
          }
          dispatch(
            getCouponList(
              postdata,
              (res: any) => {
                setCouponList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const handleOnEdit = (e: any) => {
    e.preventDefault();
    const postData = {
      couponType: type?._id,
      couponName: couponName,
      discPercentage: discPercent,
      startDate: startDate,
      endDate: endDate,
      isActive: toggle,
    };
    if (
      postData["couponType"] === null ||
      postData["couponType"] === undefined ||
      postData["couponType"] === ""
    ) {
      delete postData["couponType"];
    }
    if (
      postData["couponName"] === null ||
      postData["couponName"] === undefined ||
      postData["couponName"] === ""
    ) {
      delete postData["couponName"];
    }
    if (
      postData["discPercentage"] === null ||
      postData["discPercentage"] === undefined ||
      postData["discPercentage"] === ""
    ) {
      delete postData["discPercentage"];
    }
    if (
      postData["startDate"] === null ||
      postData["startDate"] === undefined ||
      postData["startDate"] === ""
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === null ||
      postData["endDate"] === undefined ||
      postData["endDate"] === ""
    ) {
      delete postData["endDate"];
    }
    if (
      postData["isActive"] === null ||
      postData["isActive"] === undefined ||
      postData["isActive"] === ""
    ) {
      delete postData["isActive"];
    }
    dispatch(
      updateCoupon(
        postData,
        couponId,
        (res: any) => {
          setIsEdit(false);
          const postdata = {
            couponName: couponName,
            startDate: startDate,
            endDate: endDate,
          };
          if (
            postdata["couponName"] === null ||
            postdata["couponName"] === undefined ||
            postdata["couponName"] === ""
          ) {
            delete postdata["couponName"];
          }
          if (
            postdata["startDate"] === null ||
            postdata["startDate"] === undefined ||
            postdata["startDate"] === ""
          ) {
            delete postdata["startDate"];
          }
          if (
            postdata["endDate"] === null ||
            postdata["endDate"] === undefined ||
            postdata["endDate"] === ""
          ) {
            delete postdata["endDate"];
          }
          dispatch(
            getCouponList(
              postdata,
              (res: any) => {
                setCouponList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <AdminTable
          tableData={couponList ? couponList : []}
          pagename={"CouponCode"}
          tableHead={[
            "S.N",
            "COUPON NAME",
            "DISCOUNT PERCENT",
            "END DATE",
            "STATUS",
            "TYPE",
            "ACTION",
          ]}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
          edit={true}
        />
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <>
        <form className="" onSubmit={handleOnEdit}>
          <div className="col-7 pt-2">
            {isCounsellorEdit ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Coupon Name"} />
                </div>
                <div className="col-7 ps-1 ">
                  <InputField
                    placeholder="Enter Coupon Name"
                    value={couponName}
                    isRequired={true}
                    onChangeInput={(value: any) => onchangeCouponName(value)}
                    maxlength={length_TwoHundredFifty - 1}
                    minlength={length_Two + 1}
                  />
                </div>
              </div>
            )}
            {isCounsellorEdit ? (
              <>
                <div className="d-flex mb-2 align-items-center">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Country"} />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Country"
                      setInputText={(value: any) => onChangeCountry(value)}
                      value={country}
                      options={countryList}
                      disabled
                    />
                  </div>
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Course Type"} />
                  </div>
                  <div className="col-7 ps-2 ">
                    <Dropdown
                      labelName="Select Course Type Name"
                      setInputText={(value: any) => onChangeCourseType(value)}
                      value={course}
                      options={courseList}
                      disabled
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Discount Percent"} />
              </div>
              <div className="col-7 ps-1 ">
                <InputField
                  placeholder="Enter Discount %"
                  value={discPercent}
                  isRequired={true}
                  onChangeInput={(value: any) => onchangeDiscPercent(value)}
                  maxlength={length_TwoHundredFifty - 1}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Validity"} />
              </div>
              <div className="d-flex col-12 ">
                <div className="d-flex col-6">
                  <LabelField lableName={"Start Date"} />
                  <DatePicker
                    value={startDateEdit}
                    setInputText={(value: string) => setStartDateEdit(value)}
                    defaultValue={startDateEdit}
                  />
                </div>
                <div className="d-flex col-6  ">
                  <LabelField lableName={"End Date"} />
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => setEndDate(value)}
                    mindate={mindate}
                    defaultValue={endDate}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Status"} />
              </div>
              <div className="col-2 ps-1 ">
                <ToggleSwitchbtn
                  getToggleval={(value: any) => {
                    changeToggle(value);
                  }}
                  isActive={toggle}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Save & Update"} />
          </div>
        </form>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT COUPON"}
        isEditMode={isEdit}
        setisEditMode={setIsEdit}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        {isEdit !== true ? (
          <div>
            <div className=" col-7 p-2">
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Type"} />
                </div>
                <div className="col-7 ps-2 ">
                  <CustomDropdown
                    lableName="Select Type"
                    setInputText={(value: any) => onChangeType(value)}
                    value={type}
                    options={TypeList}
                    Isrequired={true}
                    reset={resetTypeList}
                  />
                </div>
              </div>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Status"} />
                </div>
                <div className="col-7 ps-2 ">
                  <CustomDropdown
                    lableName="Select Status"
                    setInputText={(value: any) => onChangeCouponStatus(value)}
                    value={couponStatus}
                    options={CouponStatusList}
                    Isrequired={true}
                    reset={resetCouponStatusList}
                  />
                </div>
              </div>

              {type?._id === 2 ? (
                <>
                  <div className="d-flex mb-2 align-items-center">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Coupon Name"} />
                    </div>
                    <div className="col-7 ps-1 ">
                      <InputField
                        placeholder="Enter Coupon Name"
                        value={couponName}
                        onChangeInput={(value: any) =>
                          onchangeCouponName(value)
                        }
                        maxlength={length_TwoHundredFifty - 1}
                        minlength={length_Two + 1}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-2 align-items-center">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Validity"} />
                    </div>
                    <div className="d-flex col-12 ">
                      <div className="d-flex col-4">
                        <LabelField lableName={"Start Date"} />
                        <div className="col-6">
                          <DatePicker
                            value={startDate}
                            setInputText={(value: string) =>
                              setStartDate(value)
                            }
                            mindate={mindate}
                          />
                        </div>
                      </div>
                      <div className="d-flex col-7  ">
                        <LabelField lableName={"End Date"} />
                        <div className="col-3">
                          <DatePicker
                            value={endDate}
                            setInputText={(value: string) => setEndDate(value)}
                            mindate={mindate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {type?._id === 1 ? (
                <div className="d-flex mb-2 align-items-center">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Discount Percent"} />
                  </div>
                  <div className="col-7 ps-1 ">
                    <InputField
                      placeholder="Enter Discount %"
                      value={discPercent}
                      isRequired={true}
                      onChangeInput={(value: any) => onchangeDiscPercent(value)}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
              ) : null}
              <div className=" d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-dark mb-3 align-items-center liveSearchBtn mt-2"
                  onClick={() => onChange()}
                  disabled={type === "" || couponStatus === "" ? true : false}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {isEdit ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
