import "../App.css";
import { useDispatch } from "react-redux";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
  useState,
} from "react";
import moment from "moment";
import { dateFormat } from "../Utils/appConstants";
import ToggleSwitchbtn from "./toggleSwitchBtn";
import { minConvertTime } from "../Utils/dateFormat";
import { changeActiveStatusExamset } from "../Redux/Actions/AdminAction";
import parser from "html-react-parser";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Backdrop from "@mui/material/Backdrop";
import ImagePickerAndViewer from "./imagePickerAndViewer";

interface props {
  tableData: any;
  tableHead: any;
  handleEdit?: any;
  handleEditBatch?: any;
  handleDelete?: any;
  pagename?: any;
  edit?: any;
  type?: any;
  className?: any;
  addOnButton?: any;
  handleAddOnButton?: any;
  addOnButtonLabel?: any;
  handleCheck?: any;
  adddeleteButton?: any;
  page?: any;
  rowsPerPage?: any;
  handleCancelButton?: any;
  handleEndLiveButton?: any;
  handleRescheduleButton?: any;
  addOnButtonClass?: any;
  handleToggle?: any;
  handleNavigate?: any;
  onstatusChanged?: any;
  handleDeactivate?: any;
}

function PaginationTable(props: props) {
  const [open, setOpen] = useState(false);
  const [popimg, setpopimg] = useState("");
  const {
    tableData,
    tableHead,
    page,
    rowsPerPage,
    pagename,
    handleCancelButton,
    handleEndLiveButton,
    handleRescheduleButton,
    edit,
    handleEdit,
    handleDelete,
    handleCheck,
    addOnButton,
    handleAddOnButton,
    addOnButtonLabel,
    addOnButtonClass,
    handleToggle,
    handleNavigate,
    onstatusChanged,
    handleDeactivate,
  } = props;
  const dispatch = useDispatch();

  // const onstatusChanged = (event: any, examSetId: any) => {
  //   const PostData: any = {
  //     isActive: event?.target?.checked ? event?.target?.checked : false,

  //   }
  //   dispatch(
  //     changeActiveStatusExamset(
  //       PostData,
  //       event.target.id,
  //       (res: any) => {
  //       },
  //       () => { }
  //     )
  //   );
  // };

  const handleimage = (val: any) => {
    setpopimg(val);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderQuestionTitle = (title: { description: string }[]) => {
    return title?.map((item: any) => {
      return item?.descType === "text" ? (
        <div className="" key={item?._id}>
          {parser(item?.description)}
        </div>
      ) : (
        <img
          className="qusimg cursor"
          alt={item?.description}
          key={item?._id}
          src={item?.description}
          onClick={() => handleimage(item?.description)}
        />
      );
    });
  };
  // const getSeconds = (ttt) => {
  //   // let seconds = minutes * 60;
  //   const { minutes, hours, seconds } = moment.duration(ttt, "minute")._data;
  //   let temp =
  //     `${hours < 10 ? "0" + hours : hours}` +
  //     ":" +
  //     `${minutes < 10 ? "0" + minutes : minutes}` +
  //     ":" +
  //     `${seconds < 10 ? "0" + seconds : seconds}`;
  //   return <td className="col-2">{temp}</td>;
  // };

  let temp = 0;
  temp = page * rowsPerPage + 1;

  return (
    <div className="container-fluid pe-0 ps-0">
      <div className="py-2">
        <table className="w-100">
          <thead className=" font-12">
            <tr>
              {tableHead?.map(
                (
                  data:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | null
                    | undefined,
                  index: Key | null | undefined
                ) => {
                  return <th key={index}>{data}</th>;
                }
              )}
            </tr>
          </thead>
          <tbody className="">
            {tableData?.length > 0 ? (
              tableData?.map((item: any, index: any) => {
                temp++;
                return (
                  <tr key={item._id}>
                    {pagename === "CounsellingLeads" ||
                      pagename === "userdata" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ||
                      pagename === "LandingPageleads" ||
                      pagename === "LandingPageleadsExport" ||
                      pagename === "meetingList" ||
                      pagename === "teacherData" ||
                      pagename === "ExamDescriprtion" ||
                      pagename === "CourseDescription" ||
                      pagename === "RegisteredUsers" ||
                      pagename === "subject" ||
                      pagename === "ExamSyllabus" ||
                      pagename === "livevideo" ||
                      pagename === "updatePackage" ||
                      pagename === "examdates" ||
                      pagename === "Recordedvideo" ||
                      pagename === "Pattern" ||
                      pagename === "question" ||
                      pagename === "Notification" ||
                      pagename === "ExamSet" ||
                      pagename === "upcomingSchedule" ||
                      pagename === "Feedback" ||
                      pagename === "BatchFeedbackDataList" ||
                      pagename === "Updatequestion" ||
                      pagename === "transaction" ||
                      pagename === "updatedBlog" ||
                      pagename === "tutorNetwork" ||
                      pagename === "marketingLandingpage" ||
                      pagename === "marketingLandingpageExport" ||
                      pagename === "feature" ||
                      pagename === "globalSetting" ||
                      pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ||
                      pagename === "Reschedule" ||
                      pagename === "teacherConversion" ||
                      pagename === "dsaConversion" ||
                      pagename === "conversionPopup" ||
                      pagename === "bbbServerTimings" ||
                      pagename === "PtmFeedback" ||
                      pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ||
                      pagename === "AffilateLandingPageleads" ||
                      pagename === "AffilateMarketingDemoSchedule" ||
                      pagename === " updateQuestionBank" ||
                      pagename === "ImportQuestionBank" ||
                      pagename === "TransactionStatus" ? (
                      <td>{temp - 1}.</td>
                    ) : (
                      <td>{index + 1}</td>
                    )}
                    {pagename === "transaction" ? (
                      <td>{item?.orderNo ? item?.orderNo : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "transaction" ? (
                      <td>
                        {item?.orderDate
                          ? moment(item?.orderDate).format(dateFormat.yyyymmddA)
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "userdata" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ||
                      pagename === "teacherData" ||
                      pagename === "subject" ||
                      pagename === "Recordedvideo" ||
                      pagename === "subtopic" ||
                      pagename === "question" ||
                      pagename === "transaction" ||
                      pagename === "lecture" ? (
                      <td>{item.name ? item.name : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "lecture" ? (
                      item?.subTopicId ? (
                        <td>
                          {item?.subTopicId?.name
                            ? item?.subTopicId?.name
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subtopic" || pagename === "lecture" ? (
                      item?.topicId ? (
                        <td>
                          {item?.topicId?.name ? item?.topicId?.name : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subtopic" || pagename === "lecture" ? (
                      item?.priorityOrder ? (
                        <td>
                          {item?.priorityOrder ? item?.priorityOrder : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subtopic" || pagename === "lecture" ? (
                      item?.levelId ? (
                        <td>
                          {item?.levelId === 1
                            ? "Level 1"
                            : item?.levelId === 2
                              ? "Level 2"
                              : "Level 3"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Updatequestion" ? (
                      <td>
                        {item?.title ? renderQuestionTitle(item?.title) : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "Updatequestion" ? (
                      <td>{item?.correctMarks ? item?.correctMarks : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "Pattern" ? (
                      <td>{item.name ? item.name : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "teacherData" || pagename === "userdata" ? (
                      // pagename === "DsaUserdata"
                      <td>{item.empCreatedId ? item.empCreatedId : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "userdata" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ? (
                      <td>
                        {item?.departmentId ? item?.departmentId?.name : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ? (
                      <td>
                        {item?.isActive === true ? "Activated" : "Deactivated"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "userdata" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ? (
                      <td>{item?.userRoleId ? item?.userRoleId?.name : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "CounsellingLeads" ||
                      pagename === "RegisteredUsers" ? (
                      <td>
                        {item.firstName && item.lastName
                          ? `${item.firstName} ${item.lastName}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "userdata" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ||
                      pagename === "teacherData" ||
                      pagename === "RegisteredUsers" ||
                      pagename === "transaction" ? (
                      <td>{item.mobile ? item.mobile : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "transaction" ? (
                      <td>{item?.amount ? item?.amount : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "transaction" ? (
                      <td>{item?.batch?.name ? item?.batch?.name : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "transaction" ? (
                      <td>{item?.duration ? item?.duration : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "transaction" ? (
                      <td>{item?.paymentStatus ? item?.paymentStatus : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "transaction" ? (
                      <td>{item?.status ? item?.status : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "userdata" ||
                      pagename === "teacherData" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ? (
                      <td>{item?.username ? item?.username : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "userdata" ||
                      pagename === "teacherData" ||
                      pagename === "DsaUserdata" ||
                      pagename === "DsaCompanydata" ? (
                      <td>{item?.doj ? item?.doj : "-"}</td>
                    ) : (
                      ""
                    )}
                    {/* {
                      pagename === "userdata" ?
                        <td>
                          {item?.isActive ? "Yes" : "No"}
                        </td>
                        : ""
                    } */}
                    {pagename === "teacherData" || pagename === "userdata" ? (
                      <td>
                        <ToggleSwitchbtn
                          id={item.empId}
                          getToggleval={(e: any) => {
                            handleToggle(e);
                          }}
                          isActive={item?.isActive}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "CounsellingLeads" ? (
                      <td>{item.mobile ? item.mobile : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "CounsellingLeads" ||
                      pagename === "RegisteredUsers" ? (
                      <td>{item.email ? item.email : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "RegisteredUsers" ? (
                      <td>{item?.examtypeId ? item?.examtypeId?.name : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "CounsellingLeads" ? (
                      <td>{item.currentSchool ? item.currentSchool : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "CounsellingLeads" ? (
                      <td>
                        {item.createdAt
                          ? moment(item.createdAt).format(dateFormat.yyyymmddA)
                          : ""}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "CounsellingLeads" ? (
                      <td>{item.gradedesc ? item.gradedesc : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "meetingList" ? (
                      item?.name ? (
                        <td className="text-break">{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "meetingList" ? (
                      item?.mailId ? (
                        <td className="text-break">{item?.mailId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "meetingList" ? (
                      item?.password ? (
                        <td className="text-break">{item?.password}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "meetingList" ? (
                      item?.meetingId ? (
                        <td className="text-break">{item?.meetingId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "meetingList" ? (
                      item?.attentivePass ? (
                        <td className="text-break">{item?.attentivePass}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "meetingList" ? (
                      item?.meetingInviteLink ? (
                        <td className="text-break">
                          {item?.meetingInviteLink}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "ExamDescriprtion" ?
                      <td className="text-break">-</td> : ""
                    }
                      {pagename === "ExamDescriprtion" ?
                      <td className="text-break">-</td> : ""
                    } */}
                    {pagename === "CourseDescription" ? (
                      <td className="text-break">{item?.examTypeId?.name}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "CourseDescription" ? (
                      <td className="text-break">
                        {item?.maincategoryId?.name}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "CourseDescription" ? (
                      <td className="text-break">
                        {item?.courseDescriptions?.join(", ")}
                      </td>
                    ) : (
                      ""
                    )}
                    {/* ********************Exam Description************** */}
                    {pagename === "ExamDescriprtion" ? (
                      item?.examTypeId ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ExamDescriprtion" ? (
                      item?.examDesc ? (
                        <td>{item?.examDesc}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ExamSyllabus" ? <td>-</td> : null}
                    {pagename === "ExamSyllabus" ? <td>-</td> : null}
                    {/* ************************LivePremierVideo**************** */}
                    {pagename === "livevideo" ? (
                      // item?.lectureData ? (
                      <td>
                        {item?.lectureData?.map((data: any) => {
                          return data?.lectureTitle?.map((value: any) => {
                            return <li>{value?.title}</li>;
                          });
                        })}
                      </td>
                    ) : // ) : (
                      //   <td>-</td>
                      // )
                      null}
                    {/* {pagename === "livevideo" ? (
                      item?.bbbMeetingId?.mailId ? (
                        <td>{item?.bbbMeetingId?.mailId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {pagename === "livevideo" ? (
                      item?.student?.name ? (
                        <td>{item?.student?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "livevideo" ? (
                      item?.subject?.name ? (
                        <td>{item?.subject?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "livevideo" ? (
                      item?.teacherId?.name ? (
                        <td>{item?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "livevideo" ? (
                      <td>
                        {item?.lectureData?.map((data: any) => {
                          return data?.lectureTitle?.map((value: any) => {
                            return (
                              <li>
                                {moment(value.scheduleFrom).format(
                                  "DD-MM-YYYY"
                                )}
                                ,{value?.sessionTime}
                              </li>
                            );
                          });
                        })}
                      </td>
                    ) : null}
                    {/* ************************LiveRecordedVideo**************** */}
                    {pagename === "Recordedvideo" ? (
                      item?.subjectId?.name ? (
                        <td>{item?.subjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Recordedvideo" ? (
                      <td>{item?.topicId?.name}</td>
                    ) : null}
                    {/* ************************Topic**************** */}
                    {pagename === "topic" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "topic" ? (
                      item?.priorityOrder ? (
                        <td>{item?.priorityOrder}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "topic" ? (
                      item?.levelId ? (
                        <td>
                          {item?.levelId === 1
                            ? "Level 1"
                            : item?.levelId === 2
                              ? "Level 2"
                              : "Level 3"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ************************UpdatePackage**************** */}
                    {pagename === "updatePackage" ? (
                      <td>{item.name ? item.name : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "updatePackage" ? (
                      item?.gradeId?.name || item?.examtypeId?.name ? (
                        <td>
                          {item?.gradeId?.name
                            ? item?.gradeId?.name
                            : item?.examtypeId?.name}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "updatePackage" ? (
                      item?.subjectId?.name ? (
                        <td>{item?.subjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "updatePackage" ? (
                      item?.sectionId?.name ? (
                        <td>{item?.sectionId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "updatePackage" ? (
                      item?.maincategoryId?.name ? (
                        <td>{item?.maincategoryId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "updatePackage" ? (
                      <td>{item?.isCourseActive === true ? "Yes" : "No"}</td>
                    ) : (
                      ""
                    )}
                    {/* ***************************Exam Date Icon*************************** */}
                    {/* ***********************************Exam Set*********************************88 */}
                    {pagename === "question" ? (
                      <td>{`${item?.addedQuestions} / ${item?.totalQuestion}`}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "question" ? (
                      <td>{item?.model ? "Yes" : "NO"}</td>
                    ) : (
                      ""
                    )}
                    {/* {
                      pagename === "question" ?
                        <td>
                          <ToggleSwitchbtn
                            id={item.empId}
                            getToggleval={(e: any) => { handleToggle(e); }}
                            isActive={item?.isActive}
                          />
                        </td>
                        : ""
                    } */}
                    {/* ****************************************Natification*****************************************************/}
                    {pagename === "Notification" ? (
                      item?.notificationTitle ? (
                        <td>{item?.notificationTitle}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Notification" ? (
                      <td>
                        {item.scheduleAt
                          ? moment(item.scheduleAt).format(dateFormat.yyyymmddA)
                          : ""}
                      </td>
                    ) : (
                      ""
                    )}
                    {/* {pagename === "Notification" ? (
                      item?.scheduleFor ? (
                        <td>{item?.scheduleFor}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {/* *****************************************ExamSetDpp/Unit/Mock****************************************************/}
                    {pagename === "ExamSet" ? (
                      item?.examSetId ? (
                        <td>{item?.examSetId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ExamSet" || pagename === "examdates" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ExamSet" ? (
                      item?.totalQuestion >= 0 && item?.totalQuestion >= 0 ? (
                        <td>
                          {item?.addedQuestion}/{item?.totalQuestion}
                        </td>
                      ) : (
                        <td>0/0</td>
                      )
                    ) : null}
                    {/* {pagename === "ExamSet" ? (
                      item?.priorityOrder ? (
                        <td>{item?.priorityOrder}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {/* {pagename === "ExamSet" ? (
                        item?.isActive != null ? (
                          <td>
                          <ToggleSwitchbtn
                            id={item._id}
                            getToggleval={(e: any) => { handleToggle(e); }}
                            isActive={item?.isActive}
                          />
                        </td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null} */}
                    {pagename === "ExamSet" ? (
                      item?.isActive != null ? (
                        <td style={{ width: "5%" }} className="removelabel">
                          <ToggleSwitchbtn
                            id={item.examSetId}
                            key={item.examSetId}
                            isActive={item?.isActive ?? false}
                            getToggleval={(e: any) =>
                              onstatusChanged(e, item._id)
                            }
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ExamSet" ? (
                      item?.examDuration != null ? (
                        <td>{minConvertTime(item?.examDuration)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* *************************************************Live Upcoming*************************************************************** */}
                    {pagename === "upcomingSchedule" ? (
                      item?.scheduleFrom ? (
                        <td>
                          {" "}
                          {item.scheduleFrom
                            ? moment(item.scheduleFrom).format(
                              dateFormat.yyyymmdd
                            )
                            : ""}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upcomingSchedule" ? (
                      item?.sessionTime ? (
                        <td>
                          {" "}
                          {item.sessionTime
                            ? moment(item.sessionTime, "HH:mm:ss").format(
                              "hh:mm:ss a"
                            )
                            : ""}{" "}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upcomingSchedule" ? (
                      item?.package?.name ? (
                        <td> {item.package?.name} </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upcomingSchedule" ? (
                      item?.title ? (
                        <td>{item?.title}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upcomingSchedule" ? (
                      item?.createdAt ? (
                        <td>
                          {item.createdAt
                            ? moment(item.createdAt).format(dateFormat.yyyymmdd)
                            : ""}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* *****************************************feedback************************************************ */}
                    {pagename === "Feedback" ? (
                      item?.Course?.name ? (
                        <td>{item?.Course?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Feedback" ? (
                      item?.Exam?.name ? (
                        <td>{item?.Exam?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Feedback" ? (
                      item?.Subject?.name ? (
                        <td>{item?.Subject?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Feedback" ? (
                      item?.Package?.name ? (
                        <td>{item?.Package?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Feedback" ? (
                      item?.review_count ? (
                        <td>{item?.review_count}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Feedback" ? (
                      item?.overall_rating ? (
                        <td>{item?.overall_rating}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* subject feedback */}
                    {pagename === "BatchFeedbackDataList" ? (
                      item?.RecordedVideo?.name ? (
                        <td>{item?.RecordedVideo?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BatchFeedbackDataList" ? (
                      item?.Subject?.name ? (
                        <td>{item?.Subject?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BatchFeedbackDataList" ? (
                      item?.Topic?.name ? (
                        <td>{item?.Topic?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BatchFeedbackDataList" ? (
                      item?.SubTopic?.name ? (
                        <td>{item?.SubTopic?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BatchFeedbackDataList" ? (
                      item?.review_count ? (
                        <td>{item?.review_count}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BatchFeedbackDataList" ? (
                      item?.overall_rating ? (
                        <td>{item?.overall_rating}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ***********************Blog****************** */}
                    {pagename === "updatedBlog" ? (
                      item?.title ? (
                        <td>{item?.title}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "updatedBlog" ? (
                      <td>
                        {item?.createdAt
                          ? moment(item?.createdAt).format(dateFormat.yyyymmddA)
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {/* *****************Sales*************** */}
                    {pagename === "tutorNetwork" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "LandingPageleads" ||
                      pagename === "LandingPageleadsExport" ||
                      pagename === "marketingLandingpageExport" ||
                      pagename === "AffilateLandingPageleads" ||
                      pagename === "AffilateMarketingDemoSchedule" ? (
                      item?.firstName ? (
                        <td>{`${item?.firstName} ${item?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "marketingLandingpage" ? (
                      item?.firstName ? (
                        <td>{item?.firstName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "tutorNetwork" ||
                      pagename === "LandingPageleads" ||
                      pagename === "LandingPageleadsExport" ||
                      pagename === "marketingLandingpage" ||
                      pagename === "marketingLandingpageExport" ||
                      pagename === "AffilateLandingPageleads" ||
                      pagename === "AffilateMarketingDemoSchedule" ? (
                      item?.mobile ? (
                        <td>{item?.mobile}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "tutorNetwork" ||
                      pagename === "LandingPageleads" ||
                      pagename === "LandingPageleadsExport" ||
                      pagename === "marketingLandingpage" ||
                      pagename === "marketingLandingpageExport" ||
                      pagename === "AffilateLandingPageleads" ||
                      pagename === "AffilateMarketingDemoSchedule" ? (
                      item?.email ? (
                        <td>{item?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "marketingLandingpage" ? (
                      item?.coursetypeName ? (
                        <td>{item?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "marketingLandingpage" ? (
                      item?.gradedesc ? (
                        <td>{item?.gradedesc}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "tutorNetwork" ||
                      pagename === "marketingLandingpage" ? (
                      item?.subjectName ? (
                        <td>{item?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "LandingPageleads" ||
                      pagename === "LandingPageleadsExport" ||
                      pagename === "marketingLandingpage" ||
                      pagename === "marketingLandingpageExport" ||
                      pagename === "AffilateLandingPageleads" ||
                      pagename === "AffilateMarketingDemoSchedule" ? (
                      item?.createdAt ? (
                        <td>
                          {moment(item?.createdAt).format(dateFormat.yyyymmddA)}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "LandingPageleads" ||
                      pagename === "LandingPageleadsExport" ||
                      pagename === "marketingLandingpage" ||
                      pagename === "marketingLandingpageExport" ||
                      pagename === "AffilateLandingPageleads" ||
                      pagename === "AffilateMarketingDemoSchedule" ? (
                      item?.statusId ? (
                        <td>
                          {item?.statusId === 2 ? "Scheduled" : "Unscheduled"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ************************Feature************************** */}
                    {pagename === "feature" ? (
                      item?.features ? (
                        <td>{item?.features}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ******************************globalSetting************************ */}
                    {pagename === "globalSetting" ? (
                      item?.coursetypeName ? (
                        <td>{item?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "globalSetting" ? (
                      item?.examtypeName ? (
                        <td>{item?.examtypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "globalSetting" ? (
                      item?.subjectName ? (
                        <td>{item?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "globalSetting" ? (
                      item?.isNative ? (
                        <td>{item?.isNative === true ? "Native" : "Indian"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "globalSetting" ? (
                      item?.charges ? (
                        <td>{item?.charges}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "globalSetting" ? (
                      item?.currency ? (
                        <td>{item?.currency}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Feedback" ||
                      pagename === "BatchFeedbackDataList" ||
                      pagename === "AffilateLandingPageleads" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleNavigate(item)}
                      >
                        View Details
                      </td>
                    ) : null}
                    {/* ****************************************Payment Status************************************************ */}
                    {pagename === "PaymentstatusList" ? (
                      <td>{item?.OrderNo ? item?.OrderNo : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "CxoCounsellorPayments" ? (
                      <td>
                        {item?.counsellorName ? item?.counsellorName : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>
                        {" "}
                        {moment(item?.item?.OrderDate).format(
                          dateFormat.yyyymmddA
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>{item?.name ? item?.name : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>
                        {item?.coursetypeName ? item?.coursetypeName : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>{item?.packageName ? item?.packageName : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>{item?.levelId ? item?.levelId : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>{item?.TotalAmount ? item?.TotalAmount : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ? (
                      <td>
                        {item?.remainingAmount ? item?.remainingAmount : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ? (
                      <td>{item?.installment ? item?.installment : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>{item?.couponName ? item?.couponName : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>
                        {item?.discountedAmount ? item?.discountedAmount : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "PaymentstatusList" ||
                      pagename === "CxoCounsellorPayments" ? (
                      <td>
                        {item?.paymentStatusCode === 200
                          ? "Successfull"
                          : item?.paymentStatusCode === 400 ||
                            item?.paymentStatusCode === 1
                            ? "Cancel"
                            : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {/* ***************Reschedule******************************* */}
                    {pagename === "Reschedule" ? (
                      item?.title ? (
                        <td>{item?.title}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Reschedule" ? (
                      item?.firstName ? (
                        <td>{`${item?.firstName} ${item?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Reschedule" ? (
                      item?.teacherName ? (
                        <td>{item?.teacherName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Reschedule" ? (
                      item?.scheduleFrom ? (
                        <td>{`${item?.scheduleFrom}, ${item?.sessionTime}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Reschedule" ? (
                      <td>
                        <button
                          type="button"
                          className="btn btn-secondary mb-0 align-items-center searchButton"
                          onClick={() => handleEdit(item)}
                        >
                          Request
                        </button>
                      </td>
                    ) : null}
                    {/* **************************8teacherConversion******************* */}
                    {pagename === "teacherConversion" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "dsaConversion" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacherConversion" ||
                      pagename === "dsaConversion" ? (
                      item?.demoCount ? (
                        <td>{item?.demoCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacherConversion" ||
                      pagename === "dsaConversion" ? (
                      item?.paymentCount ? (
                        <td>{item?.paymentCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacherConversion" ||
                      pagename === "dsaConversion" ? (
                      item?.conversion ? (
                        <td>{item?.conversion}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacherConversion" ||
                      pagename === "dsaConversion" ? (
                      item?.rating ? (
                        <td>{item?.rating}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacherConversion" ||
                      pagename === "dsaConversion" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleNavigate(item)}
                      >
                        View Details
                      </td>
                    ) : null}
                    {/* **************************conversionPopup******************* */}
                    {pagename === "conversionPopup" ? (
                      item?.firstName ? (
                        <td>{`${item?.firstName.toUpperCase()} ${item?.lastName.toUpperCase()}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "conversionPopup" ? (
                      item?.coursetypeName ? (
                        <td>{item?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "conversionPopup" ? (
                      item?.examtypeName ? (
                        <td>{item?.examtypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "conversionPopup" ? (
                      item?.gradedesc ? (
                        <td>{item?.gradedesc}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "conversionPopup" ? (
                      item?.subjectName ? (
                        <td>{item?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "conversionPopup" ? (
                      <td>
                        {item?.admissionDate
                          ? moment(item?.admissionDate).format(
                            dateFormat.yyyymmddA
                          )
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {pagename === "conversionPopup" ? (
                      item?.admissionStatus ? (
                        <td>{item?.admissionStatus === true ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "conversionPopup" ? (
                      item?.source ? (
                        <td>{item?.source}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ************************bbbServerTimings***************** */}
                    {pagename === "bbbServerTimings" ? (
                      <td>
                        {item?.serverFreeStartTime
                          ? moment
                            .utc(item?.serverFreeStartTime)
                            .format("DD-MM-YYYY, HH:mm:ss")
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {pagename === "bbbServerTimings" ? (
                      <td>
                        {item?.serverFreeEndTime
                          ? moment
                            .utc(item?.serverFreeEndTime)
                            .format("DD-MM-YYYY, HH:mm:ss")
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {pagename === "bbbServerTimings" ? (
                      <td>
                        {item?.ServerStartIndianTime
                          ? moment
                            .utc(item?.ServerStartIndianTime)
                            .format("DD-MM-YYYY, HH:mm:ss")
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {pagename === "bbbServerTimings" ? (
                      <td>
                        {item?.ServerEndIndianTime
                          ? moment
                            .utc(item?.ServerEndIndianTime)
                            .format("DD-MM-YYYY, HH:mm:ss")
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {/* *********************IndexAcademics***************** */}
                    {pagename === "IndexAcademics" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "IndexAcademics" ? (
                      item?.indexOrder ? (
                        <td>{item?.indexOrder}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "IndexAcademics" ? (
                      <td>
                        {item?.pageAdded === true
                          ? "Page Added"
                          : "Page Not Added"}
                      </td>
                    ) : null}
                    {pagename === "IndexAcademics" ? (
                      <td>
                        {item?.pageAdded === true ? (
                          <span
                            className="text-success me-2 fw-bold cursor"
                            onClick={() => handleEdit(item)}
                          >
                            Update Page
                          </span>
                        ) : (
                          <span
                            className="text-danger me-2 fw-bold cursor"
                            onClick={() => handleEdit(item)}
                          >
                            Add Page
                          </span>
                        )}
                      </td>
                    ) : null}
                    {/* ********************PtmFeedback********************** */}
                    {pagename === "PtmFeedback" ? (
                      item?.course ? (
                        <td>{item?.course?.courseTypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "PtmFeedback" ? (
                      item?.exam ? (
                        <td>{item?.exam?.examtypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "PtmFeedback" ? (
                      item?.subject ? (
                        <td>{item?.subject?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "PtmFeedback" ? (
                      item?.lecture ? (
                        <td>{item?.lecture?.lectureName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "PtmFeedback" ? (
                      item?.OverAll_Rating ? (
                        <td>{item?.OverAll_Rating}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "PtmFeedback" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleNavigate(item)}
                      >
                        View Details
                      </td>
                    ) : null}
                    {/* ********************UpcomingInstallments********************** */}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      item?.orderNo ? (
                        <td>{item?.orderNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      item?.firstName ? (
                        <td>{`${item?.firstName} ${item?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      item?.remainingAmount ? (
                        <td>{item?.remainingAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      item?.installment ? (
                        <td>
                          {item?.installment === "secondInstallment"
                            ? "Second Installment"
                            : item?.installment === "thirdInstallment"
                              ? "Third Installment"
                              : ""}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      item?.emiStartDate ? (
                        <td>{item?.emiStartDate}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      item?.emiEndDate ? (
                        <td>{item?.emiEndDate}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "UpcomingInstallments" ||
                      pagename === "OverdueInstallments" ? (
                      <td>
                        {item?.paymentStatusCode === 200
                          ? "Successfull"
                          : item?.paymentStatusCode === 400 ||
                            item?.paymentStatusCode === 1
                            ? "Cancel"
                            : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {/* ************************************QuestionConfig************************************* */}
                    {pagename === "QuestionConfig" ? (
                      item?.coursetype?.name ? (
                        <td>{item?.coursetype?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "QuestionConfig" ? (
                      item?.examtype?.name ? (
                        <td>{item?.examtype?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "QuestionConfig" ? (
                      item?.examSetType?.name ? (
                        <td>{item?.examSetType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "QuestionConfig" ? (
                      item?.subject?.name ? (
                        <td>{item?.subject?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "QuestionConfig" ? (
                      item?.examPattern?.name ? (
                        <td>{item?.examPattern?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "QuestionConfig" ? (
                      item?.isActive ? (
                        <td>
                          {item?.isActive === true
                            ? "YES"
                            : item?.isActive === false
                              ? "NO"
                              : ""}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* *************************updateQuestionBank******************** */}
                    {pagename === "updateQuestionBank" ? (
                      item?.questionType?.name ? (
                        <td>{item?.questionType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "updateQuestionBank" ? (
                      item?.question ? (
                        <td>
                          {item?.question?.map((value: any) => {
                            return (
                              <>
                                {value?.descType === "text" ? (
                                  <>{parser(value?.description)}</>
                                ) : null}

                                {value?.descType === "image" ? (
                                  <ImagePickerAndViewer
                                    lableName={"Image"}
                                    value={value?.description}
                                    removelabel={true}
                                    disabled
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ************************TransactionStatus*************************** */}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.OrderNo ? item?.OrderNo : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.OrderDate
                          ? moment.utc(item?.OrderDate).format("DD-MM-YYYY")
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}{" "}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.name ? item?.name : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.coursetypeName ? item?.coursetypeName : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.packageName ? item?.packageName : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.levelId ? item?.levelId : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.counsellorName ? item?.counsellorName : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.TotalAmount ? item?.TotalAmount : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.remainingAmount ? item?.remainingAmount : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.installment === "firstInstallment"
                          ? "First Installment"
                          : item?.installment === "secondInstallment"
                            ? "Second Installment"
                            : item?.installment === "thirdInstallment"
                              ? "Third Installment"
                              : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.couponName ? item?.couponName : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.discountedAmount ? item?.discountedAmount : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>{item?.sellingPrice ? item?.sellingPrice : "-"} </td>
                    ) : (
                      ""
                    )}
                    {pagename === "TransactionStatus" ? (
                      <td>
                        {item?.paymentStatusCode === 200 ||
                          item?.paymentStatus === "approved"
                          ? "Successfull"
                          : item?.paymentStatusCode === 400 ||
                            item?.paymentStatus === "failed"
                            ? "Cancel"
                            : item?.paymentStatusCode === 1 ||
                              item?.paymentStatus === "created"
                              ? "Pending"
                              : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {/* ********************************** */}
                    {pagename === "meetingList" ||
                      pagename === "ExamDescriprtion" ||
                      pagename === "CourseDescription" ||
                      pagename === "subject" ||
                      pagename === "ExamSyllabus" ||
                      pagename === "userdata" ||
                      pagename === "DsaUserdata" ||
                      pagename === "teacherData" ||
                      pagename === "livevideo" ||
                      pagename === "updatePackage" ||
                      pagename === "examdates" ||
                      pagename === "Recordedvideo" ||
                      pagename === "Pattern" ||
                      pagename === "Notification" ||
                      pagename === "topic" ||
                      pagename === "subtopic" ||
                      pagename === "ExamSet" ||
                      pagename === "Updatequestion" ||
                      pagename === "updatedBlog" ||
                      pagename === "lecture" ||
                      pagename === "feature" ||
                      pagename === "globalSetting" ||
                      pagename === "QuestionConfig" ||
                      pagename === "updateQuestionBank" ||
                      pagename === "ImportQuestionBank" ? (
                      <td>
                        <span
                          className="text-primary fw-bold me-2 cursor"
                          onClick={() => handleEdit(item)}
                        >
                          Edit
                        </span>
                        {handleDelete ? (
                          <span
                            className="text-danger fw-bold cursor"
                            onClick={() => handleDelete(item)}
                          >
                            {" "}
                            Delete{" "}
                          </span>
                        ) : null}
                        {handleDeactivate && item?.isActive === true ? (
                          <span
                            className="text-danger fw-bold cursor"
                            onClick={() => handleDeactivate(item)}
                          >
                            Deactivate
                          </span>
                        ) : null}
                      </td>
                    ) : null}
                    {pagename === "CounsellingLeads1" ||
                      pagename === "DsaCompanydata" ? (
                      <td>
                        <span
                          // type="submit"
                          className="text-success me-2 fw-bold cursor"
                          onClick={() => handleEdit(item)}
                        >
                          View Details
                        </span>
                      </td>
                    ) : null}
                    {pagename === "LandingPageleads" ||
                      pagename === "marketingLandingpage" ||
                      pagename === "AffilateMarketingDemoSchedule" ? (
                      <td>
                        <button
                          type="button"
                          className="btn btn-dark mb-0 align-items-center searchButton"
                          onClick={() => handleEdit(item)}
                          disabled={item?.statusId === 2}
                        >
                          Demo Schedule
                        </button>
                      </td>
                    ) : null}
                    {addOnButton ? (
                      <span
                        // type="submit"
                        className="btn btn-dark btn-sm mt-1"
                        onClick={() => handleAddOnButton(item)}
                      >
                        {addOnButtonLabel}
                      </span>
                    ) : null}
                    {pagename === "question" ? (
                      <td>
                        <span
                          // type="submit"
                          className="btn btn-primary btn-sm rounded-pill"
                          onClick={() => handleAddOnButton(item)}
                        >
                          {addOnButtonLabel}
                        </span>
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No record found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-end ">
              <ClearOutlinedIcon
                className="colorblue cursor"
                onClick={handleClose}
              />
            </div>
            <div className="">
              <img className="img-fluid " src={popimg} alt="images" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default PaginationTable;
