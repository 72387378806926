import "../Assets/css/studentCard.css";
import user from "../Assets/images/user.jpg";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../Modals";
export default function StudentCard(props: any) {
  const { userId, userName, userEmail } = useSelector(
    (state: { Auth: AuthReducerModal }) => ({
      userId: state.Auth.userId,
      userName: state.Auth.userName,
      userEmail: state.Auth.userEmail,
    })
  );
  function tConvert(time: any) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  const onClickJoinClass = (classType: string) => {
    redirectToJitsi();
    if (classType === "LIVE CLASS") {
      const sendData = {
        lectureId: props?.item?.lectureId,
        topicId: props?.item?.topicId,
        studentId: props?.item?.studentId,
        teacherId: userId,
        teacher: true,
      };
      if(props?.item?.acadmicDataPresent){
        const encodedData = encodeURIComponent(JSON.stringify(sendData));
        window.open(
          `${process.env.REACT_APP_WEBLINK}/LectureIndex?data=${encodedData}`,
          "_blank"
        );
      }
      // window.open(`http://localhost:3700/LectureIndex?data=${encodedData}`, '_blank');
    }
  };

  const redirectToJitsi = () => {
    // let JitSiURL: any = `jitsi-meet://meet.lurnigo.com/${props?.item?.roomName}#userInfo.displayName=%22${userName}%22&userInfo.email=%22${userEmail}%22&config.prejoinConfig.enabled=false`;
    let JitSiURL: any = `jitsi-meet://meet.lurnigo.com/${props?.item?.roomName}?jwt=${props?.item?.token}`;
    window.location.href = JitSiURL;
  };

  return (
    <div className="studentCard">
      <div className="d-flex">
        <div className="userImgDiv d-flex align-items-center me-2">
          <img src={user} alt="User" className="userImg" />
        </div>
        <div className="w-100">
          <h6 className="fw-bold">{`${props?.item?.firstName} ${props?.item?.lastName}`}</h6>

          <h6>{props?.item?.title}</h6>
          <div className="gradeDiv">
            {props?.item?.gradedesc ? (
              <div>{props?.item?.gradedesc}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-2 scheduleBox rounded">
        <h6 className="text-primary">{props?.item?.coursetypeName}</h6>
        <h6>{props?.item?.UTCOffset}</h6>
        {props?.pageName === "MyClassTeacherPanel" ? (
          <h6 className="fw-bold">
            at{" "}
            {`${moment(props?.item?.scheduleTo).format("DD-MM-YYYY")} ,
              ${tConvert(props?.item?.time)}
              ${props?.item?.timezone}`}
          </h6>
        ) : (
          <h6 className="fw-bold">
            at{" "}
            {`${moment(props?.item?.date).format("DD-MM-YYYY")} ,
              ${tConvert(props?.item?.time)}
              ${props?.item?.timezone}`}
          </h6>
        )}
        {props?.pageName === "MyDemoTeacherPanel" ||
        props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Indian date & Time : </div>
            <div className="ms-1">
              <h6>
                {moment
                  .utc(props?.item?.Indian_DateTime)
                  .format("DD-MM-YYYY, HH:mm:ss")}
              </h6>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Subject : </div>
            <div className="ms-1">
              <h6>{props?.item?.subjectName}</h6>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Topic : </div>
            <div className="ms-1">
              <h6>{props?.item?.topicName}</h6>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Sub-Topic : </div>
            <div className="ms-1">
              <h6>{props?.item?.subTopicName}</h6>{" "}
            </div>
          </div>
        ) : null}

        {props?.pageName === "MyDemoTeacherPanel" ? (
          <div className="d-flex justify-content-between">
            <div className="mt-4 d-flex">
              <button
                type="button"
                className="btn btn-dark mb-2"
                onClick={() => onClickJoinClass("DEMO")}
              >
                {props?.item?.statusId === 2 ? "START DEMO" : "REJOIN DEMO"}
              </button>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex justify-content-between">
            <div className="mt-4 d-flex">
              <button
                type="button"
                className="btn btn-dark mb-2"
                onClick={() => onClickJoinClass("LIVE CLASS")}
              >
                {props?.item?.status === 1 ? "START CLASS" : "REJOIN CLASS"}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
