import React, { useState, useRef, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DownloadIcon from "@mui/icons-material/Download";
import localImages from "../Constant/localImages";

interface Props {
  open: boolean;
  handleClose: Function;
  mainImage?: string; // The main banner image source
  qrData: string; // Keeping this in case it's needed for future use
}

export default function CreateBannerPopup({
  open,
  handleClose,
  mainImage,
  qrData,
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (open) {
      drawCanvas(); // Only draw canvas when the modal is opened
    }
  }, [open, mainImage]); // Include all necessary dependencies

  const closePopup = (): void => {
    handleClose();
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png"); // Get the data URL of the canvas
      link.download = "canvasImage.png"; // Set the download file name
      link.click(); // Simulate a click to download the image
    }
  };

  const drawCanvas = async () => {
    const canvas = canvasRef.current;
    if (!canvas || !mainImage) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Clear previous canvas content
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    try {
      // Load main banner image
      const mainImg = await loadImage(mainImage);
      canvas.width = mainImg.width; // Set canvas width
      canvas.height = mainImg.height; // Set canvas height
      ctx.drawImage(mainImg, 0, 0); // Draw the main image

      // Load profile image if available
      const profileImg = await loadImage(qrData);
      ctx.drawImage(profileImg, 45, 840, 170, 210); // Adjust position as needed

      // Load QR code if available
      if (qrData) {
        const qrImg = await loadImage(qrData);
        ctx.drawImage(qrImg, canvas.width - 150, canvas.height - 150, 100, 100); // Adjust position as needed
      }

      // Draw the name text on the canvas
      ctx.font = "bold 26px Poppins"; // Set font for name
      ctx.textAlign = "center"; // Center align text
      ctx.fillStyle = "black"; // Set text color
      ctx.fillText("vipul", canvas.width / 2, canvas.height - 50); // Draw the name
    } catch (error) {
      console.error("Error loading images or drawing canvas:", error);
    }
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // CORS handling
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = src;
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="bannerPopup">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Create Banner</h5>
            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />
            </div>
          </div>

          <div className="row d-flex justify-content-between mt-2">
            <div className="col-md-6">
              {/* Preview the image before rendering it on the canvas */}
              <img src={mainImage} alt="banner" className="img-fluid" />
              {qrData && (
                <div>
                  <img
                    src={qrData}
                    alt="Profile Image"
                    className="invitationCardProfileImg"
                  />
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="mt-3 text-center">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDownload}
                >
                  <DownloadIcon /> Download Banner
                </button>
              </div>
              <div>
                <canvas
                  ref={canvasRef}
                  style={{
                    border: "1px solid black",
                    width: "100%", // Ensure canvas fits container
                    height: "auto",
                    // display: "none",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
